import bugsnagClient from "./Utility/bugsnagClient";

export const fetchLeadsqaureId = (mobile) => {

    const url = `https://api-in21.leadsquared.com/v2/LeadManagement.svc/RetrieveLeadByPhoneNumber?accessKey=u$rf3498426799e13305a90b382a9dd98c4&secretKey=55b6753b1163635a88eea3acaf57df59414dbe38&phone=${mobile}`;
    const fetchOpts = {
        method: 'GET'
    };

    return fetch(url, fetchOpts)
    .then((response) => response.json())
    
    .catch((error) => {
      bugsnagClient.notify(error);
    });
}

export const fetchProcedures = (entityId, siteCode) => {
    const url = "https://cx-server.cloudninecare.com/qkams/get/procedures";
    const req_body = {
      entityId: entityId,
      siteCode: siteCode,
    };
    const fetchOpts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req_body),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => response.json())
  
      .catch((error) => {
        //  setProcedureArr([]);
        bugsnagClient.notify(error);
      });
  };

export const fetchHospitals = () => {
  const url = `https://cx-server.cloudninecare.com/qkams/get/all/hospital/details`;

  const fetchOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, fetchOpts)
    .then((response) => response.json())
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const fetchDoctors = (siteCode) => {
    const url=`https://cx-server.cloudninecare.com/qkams/get/doctors?sitecode=${siteCode}`;
    const fetchOpts = {
        method: 'GET'
    };
    return fetch(url, fetchOpts)
        .then(response => response.json())
        .catch(error => {
            bugsnagClient.notify(error);
            // console.error(`Fetch Error =\n`, error)
        });
}
export const fetchDoctorFromId = (docId) => {
    const url=`https://cx-server.cloudninecare.com/qkams/get/doctor/profile?docId=${docId}`;
    const fetchOpts = {
        method: 'GET'
    };
    return fetch(url, fetchOpts)
        .then(response => response.json())
        .catch(error => {
            bugsnagClient.notify(error);
            // console.error(`Fetch Error =\n`, error)
        });
}

export const fetchSlots = (doctorEntityId, siteCode, procedureName) => {

    const url = 'https://cx-server2.cloudninecare.com/qkams/get/procedures/slots';
    const data = {
        "entityId": doctorEntityId,
        "siteCode": siteCode,
        "procedureName": procedureName
    }
    const fetchOpts = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(data)
    };
    return fetch(url, fetchOpts)
        .then(response => response.json())
        .catch(error => {
            bugsnagClient.notify(error);
        });
}

export const sendOTP = (mobileNumber) => {
  const url = `https://cx-server2.cloudninecare.com/api/send-otp`
  const req_body = { "mobile": mobileNumber };
  const fetchOpts = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req_body)
  };
  // console.log(fetchOpts, url);
  return fetch(url, fetchOpts)
    .then(response => response.json())
    .catch(error => {
        bugsnagClient.notify(error);
      // console.error(`Fetch Error =\n`, error)
    });
}
export const verifyOTP = (mobileNumber, otp) => {
  const url = `https://cx-server2.cloudninecare.com/api/verify-otp`
  const req_body = { "mobile": mobileNumber, "otp": otp };
  const fetchOpts = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req_body)
  };
  // console.log(fetchOpts, url);
  return fetch(url, fetchOpts)
    .then(response => response.json())
    .catch(error => {
        bugsnagClient.notify(error);
      // console.error(`Fetch Error =\n`, error)
    });
}
export const bookAppointment = (slotId, procedureName, userMobile, userName, siteCode, doctorName, slotTime, mpid) => {

    const source="Widget";
    const url = `https://cx-server.cloudninecare.com/qkams/create/appointment`
    const req_body = { slotId, procedureName, userMobile, userName, source, siteCode, doctorName, slotTime, mpid }
    const fetchOpts = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req_body)
    };
    // console.log(req_body);
    console.log(JSON.stringify(req_body));
    
    // console.log(fetchOpts, url);
    return fetch(url, fetchOpts)
        .then(response => response.json())
        .catch(error => {
            bugsnagClient.metaData=req_body;
            bugsnagClient.notify(error);
            // console.error(`Fetch Error =\n`, error)
        });
}
export const pushMobileToFirestore = (mob) => {
    let req_body = {
        "mobile": mob
    }
    const fetchOpts = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req_body)
    };
    const url=`https://us-central1-cx-server-id.cloudfunctions.net/pushLtUsersToFirestore`
    return fetch(url, fetchOpts)
        .then(response => response.json())
        .catch(error => {
            // console.error(`Fetch Error =\n`, error);
        });
};
export const getMobileFromFirestore = (mob) => {
    let req_body = {
        "mobile": mob
    }
    const fetchOpts = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req_body)
    };
    const url=`https://us-central1-cx-server-id.cloudfunctions.net/getLtUsersFromFirestore`
    return fetch(url, fetchOpts)
        .then(response => response.json())
        .catch(error => {
            // console.error(`Fetch Error =\n`, error);
        });
};
export const getPatient = (mobile) =>{
    const url=`https://cx-server.cloudninecare.com/qkams/get/patient/data?mobile=${mobile}`;
    const fetchOpts = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return fetch(url, fetchOpts)
        .then(response => response.json())
        .catch(error => {
            bugsnagClient.notify(error);
            // console.error(`Fetch Error =\n`, error)
        });
}