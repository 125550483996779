import Amplitude from "amplitude-js";

const amplitude = Amplitude.getInstance();
amplitude.init(`937892195e45118779fd0a4802afb325`);

const setUserId = (id) => {
  amplitude.setUserId(id);
};

const setUserProperties = (properties) => {
  amplitude.setUserProperties(properties);
};

const logEvent = (eventType, eventProps = {}) => {
  amplitude.logEvent(eventType, Object.assign(eventProps));
};

export default { setUserId, logEvent, setUserProperties };
