import React,{useState,useEffect,useRef} from 'react';
import {
    Grid, TextField, Button, Paper, InputAdornment, MenuItem, Radio,
    RadioGroup, FormControlLabel, FormControl, FormLabel
} from '@material-ui/core';
import {sendOTP,verifyOTP, pushMobileToFirestore, getMobileFromFirestore,fetchLeadsqaureId} from './../Api'
// import Loading from '@material-ui/core/CircularProgress';
import moment from "moment";
import Loading from '@material-ui/core/CircularProgress';
import bugsnagClient from "./../Utility/bugsnagClient";
import { ToastContainer, toast } from 'react-toastify';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import amplitude from './../Utility/Amplitude';
import TagManager from 'react-gtm-module';


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

function GetPatientDetails(props) {
    const [name,setname]=useState("");
    const [mobile,setmobile]=useState("");
    const [otp,setotp]=useState("");
    const [finalCharges, setFinalCharges] = useState("");
    const [redirectURL, setRedirectURL] = useState("");
    const [selectedProcedure,setselectedProcedure]=useState("");
    const [selectedMpid,setSelectedMpid]=useState();
    const [patientName, setPatientName] = useState("");
    const [guardianName, setGuardianName] = useState("");
    const [patientDOB, setPatientDOB] = useState(null);
    const [patientGender, setPatientGender] = useState();
    const [patientEmail, setPatientEmail] = useState("");
    const [patientMpid, setPatientMpid] = useState("");
    const [patientNameError, setPatientNameError] = useState(false);
    const [guardianNameError, setGuardianNameError] = useState(false);
    const [patientDOBError, setPatientDOBError] = useState(false);
    const [patientGenderError, setPatientGenderError] = useState(false);
    const [patientEmailError, setPatientEmailError] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [mpidError,setmpidError]=useState(false);
    const [chargesLoading, setChargesLoading] = useState(false);
    const [registeringUser, setRegisteringUser] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [mpiList, setmpiList]=useState([]);
    const [mobileError,setmobileError]=useState(false);
    const [isProcedureVideo, setIsProcedureVideo] = useState(false);
    const [paidButtonClicked, setPaidButtonClicked] = useState(false);
    const [otpSentBool,setotpSentBool]=useState(false);
    const [otpVerified,setotpVerified]=useState(false);
    const [OtpError,setOtpError]=useState(false);
    const [mpidSelected, setMpidSelected] = useState(false);
    const [userAvaliable, setUserAvaliable] = useState(false);
    const [otpTimer,setotpTimer]=useState(30);
    const textRef=useRef(null);
    const executeScroll = () => scrollToRef(textRef);
    useEffect(()=>{
        let timerRef=null;
        if(otpSentBool){
            timerRef = setInterval(() => {
                if(otpTimer>0){
                    setotpTimer(otpTimer-1);
                }
            }, 1000);
            if(otpTimer===0){
                clearInterval(timerRef);
            }
            return () => clearInterval(timerRef);
        }
        executeScroll();
    },[props.ApptDateTime,otpSentBool,otpTimer])
    const handleGenerateOTP = () => {
        setselectedProcedure(props.selectedProcedure);
        if (!mobile || (mobile && mobile.length < 10)) {
            setmobileError(true);
        }else{
            pushMobileToFirestore(mobile).then(resp=>console.log(resp));
            window.Moengage.add_unique_user_id(mobile);
            sendOTP(mobile)
                .then(resp=>{
                    setotpSentBool(true);
                })

               


                const prospectId = fetchLeadsqaureId(mobile).then(data=>{
                    const tagManagerArgs = {
                        dataLayer: {
                            event: "userid",
                            userId: data[0].ProspectID
                        },
                        dataLayerName: 'PageDataLayer',
                        events: 
                            {'event':'userid','userId': data[0].ProspectID}
                        
                    }
                    TagManager.dataLayer(tagManagerArgs);
                    console.log(data[0].ProspectID,'leadSquareId')    

                  })
               
        }
    }

    const handleDateChange = (item) => {
        setPatientDOB(item);
        setPatientDOBError(false);
    }

    const handleRegistration = () => {
        if (!patientName || (patientName && patientName.length <= 3)) {
            setPatientNameError(true);
        }
        else if (!patientGender) {
            setPatientGenderError(true);
        }
        else if (patientDOB === null || !patientDOB) {
            setPatientDOBError(true);
        }
        else if (!patientEmail || (patientEmail && !patientEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/))) {
            setPatientEmailError(true);
        }
        else if (!guardianName || (guardianName && guardianName.length <= 3)) {
            setGuardianNameError(true);
        }
        else {
            setRegisteringUser(true);
            const eventProperties = {
                "registered_user_name": patientName,
                "registered_user_gender": patientGender,
                "registered_user_DOB": moment(patientDOB).format('YYYY-MM-DD'),
                "registered_user_email": patientEmail,
                "registered_user_guardian_name": guardianName,
                ...(props.utm_city && { "utm_city": props.utm_city }),
                ...(props.utm_source && { "utm_source": props.utm_source }),
                ...(props.utm_medium && { "utm_medium": props.utm_medium }),
                ...(props.utm_campaign && { "utm_campaign": props.utm_campaign }),
                ...(props.utm_content && { "utm_content": props.utm_content }),
                ...(props.utm_hospital && { "utm_hospital": props.utm_hospital }),
                ...(props.utm_service && { "utm_service": props.utm_service }),
                ...(props.utm_term && { "utm_term": props.utm_term }),
                ...(props.utm_adposition && { "utm_adposition": props.utm_adposition }),
                ...(props.utm_adset && { "utm_adset": props.utm_adset }),
                ...(props.utm_ad && { "utm_ad": props.utm_ad }),
                ...(props.utm_keyword && { "utm_keyword": props.utm_keyword }),
                ...(props.utm_placement && { "utm_placement": props.utm_placement }),
                ...(props.utm_corporate && { "utm_corporate": props.utm_corporate }),
                ...(props.utm_campaignid && { "utm_campaignid": props.utm_campaignid }),
                ...(props.utm_adgroupid && { "utm_adgroupid": props.utm_adgroupid }),
                ...(props.utm_extentionid && { "utm_extentionid": props.utm_extentionid }),
                ...(props.utm_locinterest && { "utm_locinterest": props.utm_locinterest }),
                ...(props.utm_clicklocation && { "utm_clicklocation": props.utm_clicklocation }),
                ...(props.utm_network && { "utm_network": props.utm_network }),
                ...(props.utm_device && { "utm_device": props.utm_device }),
                ...(props.utm_devicemodel && { "utm_devicemodel": props.utm_devicemodel }),
                ...(props.utm_creative && { "utm_creative": props.utm_creative }),
                ...(props.utm_matchtype && { "utm_matchtype": props.utm_matchtype }),
                ...(props.utm_targetid && { "utm_targetid": props.utm_targetid }),
                ...(props.utm_target && { "utm_target": props.utm_target }),
                ...(props.utm_url && { "utm_url": props.utm_url })
            };
            amplitude.logEvent("webBooking_newRegistration", eventProperties);
            window.Moengage.track_event("webBooking_newRegistration", eventProperties);
            if (props.utm_source && props.utm_campaign) {
                const userProperties = {
                    "utm_source": props.utm_source,
                    "utm_campaign": props.utm_campaign
                };
                amplitude.setUserProperties(userProperties);
                window.Moengage.add_user_attribute(userProperties);
            }
            const events = eventProperties;
            const tagManagerArgs = {
                dataLayer: {
                    registrationInitiated: true,
                    events
                },
                dataLayerName: 'PageDataLayer',
                events: {
                    sendUserInfo: eventProperties
                }
            }
            TagManager.dataLayer(tagManagerArgs);
            const url = `https://qm993w4okc.execute-api.us-east-1.amazonaws.com/Prod/`;
            const reqBody = {
                "body": {
                    "registered_site_code": props.SiteCode,
                    "first_name": patientName,
                    "last_name": null,
                    "date_of_birth": moment(patientDOB).format('YYYY-MM-DD'),
                    "gender": patientGender,
                    "mobile": mobile,
                    "email": patientEmail,
                    "datetime_of_registration": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                    "nationality_country": "India",
                    "eddDate": null,
                    "addressStreet": "",
                    "addressCity": "",
                    "guardian_mobile": "",
                    "guardian_first_name": guardianName,
                    "idProofType": "",
                    "idProofNumber": "",
                    "approximate_age_in_years": null,
                    "guardian_last_name": null,
                    "guardian_relationship_with_patient": null,
                    "guardian_email": null,
                    "pinCode": "",
                    "visitPurpose": props.Doctor.department,
                    "referralSource": "Unknown"
                }
            }

            const fetchOpts = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqBody)
            };
            return fetch(url, fetchOpts)
                .then(response =>
                    response.json()).then(response => {
                        if (response && response.head && response.head.StatusValue == 200) {
                            setmpiList([{ label: patientName, value: response.head }]);
                            setRegistrationSuccess(true);
                            toast.success("Registration Successfully!", {
                                position: toast.POSITION.TOP_CENTER
                            });
                            const eventProperties = {
                                "site_code": props.SiteCode,
                                "mpid": response.head.mpid
                            };
                            amplitude.logEvent("Registration_Successful", eventProperties);
                            window.Moengage.track_event("Registration_Successful", eventProperties);
                            const events = eventProperties;
                            const tagManagerArgs = {
                                dataLayer: {
                                    registrationSuccessful: true,
                                    events
                                },
                                dataLayerName: 'PageDataLayer',
                                events: {
                                    sendUserInfo: eventProperties
                                }
                            }
                            TagManager.dataLayer(tagManagerArgs);
                        }
                        else {
                            setRegisteringUser(false);
                            toast.error("Registration Failed!", {
                                position: toast.POSITION.TOP_CENTER
                            });
                        }
                    })
                .catch(error => {
                    setRegisteringUser(false);
                    toast.error("Network Error, Please Retry!", {
                        position: toast.POSITION.TOP_CENTER
                    });
                    bugsnagClient.metaData = reqBody;
                    bugsnagClient.notify(error);
                });
        }
    }

    const getCharges = (value) => {
        var mpid = "";
        var email = "";
        var name = "";
        var mobileno = "";
        
        if (value['MPI'] == null) {
            mpid = value['mpid'];
            email = patientEmail;
            name = patientName;
            mobileno = mobile;
        }
        else {
            mpid = value['MPI'];
            email = value['email'];
            name = value['first_name'];
            mobileno = value['mobile'];

        }
        setname(name);
        setPatientMpid(mpid);
        const eventProperties = {
            "selected_patient": name,
            "selected_patient_mpid": mpid,
            ...(props.utm_city && { "utm_city": props.utm_city }),
            ...(props.utm_source && { "utm_source": props.utm_source }),
            ...(props.utm_medium && { "utm_medium": props.utm_medium }),
            ...(props.utm_campaign && { "utm_campaign": props.utm_campaign }),
            ...(props.utm_content && { "utm_content": props.utm_content }),
            ...(props.utm_hospital && { "utm_hospital": props.utm_hospital }),
            ...(props.utm_service && { "utm_service": props.utm_service }),
            ...(props.utm_term && { "utm_term": props.utm_term }),
            ...(props.utm_adposition && { "utm_adposition": props.utm_adposition }),
            ...(props.utm_adset && { "utm_adset": props.utm_adset }),
            ...(props.utm_ad && { "utm_ad": props.utm_ad }),
            ...(props.utm_keyword && { "utm_keyword": props.utm_keyword }),
            ...(props.utm_placement && { "utm_placement": props.utm_placement }),
            ...(props.utm_corporate && { "utm_corporate": props.utm_corporate }),
            ...(props.utm_campaignid && { "utm_campaignid": props.utm_campaignid }),
            ...(props.utm_adgroupid && { "utm_adgroupid": props.utm_adgroupid }),
            ...(props.utm_extentionid && { "utm_extentionid": props.utm_extentionid }),
            ...(props.utm_locinterest && { "utm_locinterest": props.utm_locinterest }),
            ...(props.utm_clicklocation && { "utm_clicklocation": props.utm_clicklocation }),
            ...(props.utm_network && { "utm_network": props.utm_network }),
            ...(props.utm_device && { "utm_device": props.utm_device }),
            ...(props.utm_devicemodel && { "utm_devicemodel": props.utm_devicemodel }),
            ...(props.utm_creative && { "utm_creative": props.utm_creative }),
            ...(props.utm_matchtype && { "utm_matchtype": props.utm_matchtype }),
            ...(props.utm_targetid && { "utm_targetid": props.utm_targetid }),
            ...(props.utm_target && { "utm_target": props.utm_target }),
            ...(props.utm_url && { "utm_url": props.utm_url })
        };
        amplitude.logEvent("webBooking_patientSelected", eventProperties);
        window.Moengage.track_event("webBooking_patientSelected", eventProperties);
        const events = eventProperties;
        const tagManagerArgs = {
            dataLayer: {
                events
            },
            dataLayerName: 'PageDataLayer',
            events: {
                sendUserInfo: eventProperties
            }
        }
        TagManager.dataLayer(tagManagerArgs);
        if (selectedProcedure === "Video Consultation") {
        setChargesLoading(true);
        const url = `https://rus0ja0gxh.execute-api.us-east-1.amazonaws.com/Prod/`;
        const reqBody = {
            "body": {
                "service_list": [
                    {
                        "site_code": props.SiteCode,
                        "service_type": "Video Consultation",
                        "patient_mpi": mpid,
                        "consultant_id": props.Doctor.lifetrenzId,
                        "date_of_appointment": moment(props.ApptDateTime).format('YYYY-MM-DD'),
                        "time_of_appointment": null,
                        "quantity": "1"
                    }
                ]
            }
        }
        const fetchOpts = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqBody)
        };
        return fetch(url, fetchOpts)
            .then(response =>
                response.json()).then(response => {
                    if (response && response.body && response.body.Data[0] && response.body.Data[0].total_payable) {
                        setFinalCharges(response.body.Data[0].total_payable);
                        const eventProperties = {
                            "charges_shown": response.body.Data[0].total_payable,
                            "selected_procedure": props.selectedProcedure,
                            "selected_slot": moment(props.ApptDateTime).format('LLL'),
                            "selected_patient_mpid": mpid,
                            ...(props.utm_city && { "utm_city": props.utm_city }),
                            ...(props.utm_source && { "utm_source": props.utm_source }),
                            ...(props.utm_medium && { "utm_medium": props.utm_medium }),
                            ...(props.utm_campaign && { "utm_campaign": props.utm_campaign }),
                            ...(props.utm_content && { "utm_content": props.utm_content }),
                            ...(props.utm_hospital && { "utm_hospital": props.utm_hospital }),
                            ...(props.utm_service && { "utm_service": props.utm_service }),
                            ...(props.utm_term && { "utm_term": props.utm_term }),
                            ...(props.utm_adposition && { "utm_adposition": props.utm_adposition }),
                            ...(props.utm_adset && { "utm_adset": props.utm_adset }),
                            ...(props.utm_ad && { "utm_ad": props.utm_ad }),
                            ...(props.utm_keyword && { "utm_keyword": props.utm_keyword }),
                            ...(props.utm_placement && { "utm_placement": props.utm_placement }),
                            ...(props.utm_corporate && { "utm_corporate": props.utm_corporate }),
                            ...(props.utm_campaignid && { "utm_campaignid": props.utm_campaignid }),
                            ...(props.utm_adgroupid && { "utm_adgroupid": props.utm_adgroupid }),
                            ...(props.utm_extentionid && { "utm_extentionid": props.utm_extentionid }),
                            ...(props.utm_locinterest && { "utm_locinterest": props.utm_locinterest }),
                            ...(props.utm_clicklocation && { "utm_clicklocation": props.utm_clicklocation }),
                            ...(props.utm_network && { "utm_network": props.utm_network }),
                            ...(props.utm_device && { "utm_device": props.utm_device }),
                            ...(props.utm_devicemodel && { "utm_devicemodel": props.utm_devicemodel }),
                            ...(props.utm_creative && { "utm_creative": props.utm_creative }),
                            ...(props.utm_matchtype && { "utm_matchtype": props.utm_matchtype }),
                            ...(props.utm_targetid && { "utm_targetid": props.utm_targetid }),
                            ...(props.utm_target && { "utm_target": props.utm_target }),
                            ...(props.utm_url && { "utm_url": props.utm_url })
                        };
                        amplitude.logEvent("webBooking_chargesShown", eventProperties);
                        window.Moengage.track_event("webBooking_chargesShown", eventProperties);
                        const events = eventProperties;
                        const tagManagerArgs = {
                            dataLayer: {
                                events
                            },
                            dataLayerName: 'PageDataLayer',
                            events: {
                                sendUserInfo: eventProperties
                            }
                        }
                        TagManager.dataLayer(tagManagerArgs);
                        handlePayButtonClicked(response.body.Data[0].total_payable, mpid, email, name, mobileno);
                    }
                    else{
                        setChargesLoading(false);
                        setPaidButtonClicked(false);
                        toast.error("Error Fetching Charges!", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
            .catch(error => {
                setChargesLoading(false);
                setPaidButtonClicked(false);
                toast.error("Error Fetching Charges!", {
                    position: toast.POSITION.TOP_CENTER
                });
                bugsnagClient.metaData = reqBody;
                bugsnagClient.notify(error);
            });
        }
        else {
            return;
        }
    }

    const handlePayButtonClicked = (finalCharge, mpid, email, name, mobileno) => {
        const url = `https://cx-server.cloudninecare.com/payuSubmit/form`;
        const reqBody = {
            "sitecode": props.SiteCode,
            "productinfo": props.SlotId,
            "email": email,
            "firstname": name,
            "phone": mobileno,
            "udf1": mpid,
            "udf2": `sms~${props.Doctor.lifetrenzId}~${props.Doctor.name}~${props.Hospital}~sms~Video Consultation`,
            "udf3": props.SiteCode,
            "udf4": moment(props.ApptDateTime).format('YYYY-MM-DD'),
            "udf5": moment(props.ApptDateTime).format("hh:mm a"),
            "amount": finalCharge,
            "SURL": `https://service.cloudninecare.com/paymentresponse.html?m=${mpid}&q=${props.SlotId}&t=Appointment&d=sms`
        }
        const fetchOpts = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqBody)
        };
        return fetch(url, fetchOpts)
            .then(response =>
                response.json()).then(response => {
                    if (response && response.url) {
                        setChargesLoading(false);
                        setPaidButtonClicked(true);
                        setRedirectURL(response.url);
                    }
                    else {
                        setChargesLoading(false);
                        setPaidButtonClicked(false);
                        toast.error("Error Fetching Charges!", {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
            .catch(error => {
                setChargesLoading(false);
                setPaidButtonClicked(false);
                toast.error("Error Fetching Charges!", {
                    position: toast.POSITION.TOP_CENTER
                });
                bugsnagClient.metaData = reqBody;
                bugsnagClient.notify(error);
            });
    }

    const redirectToPayment = () => {
        const eventProperties = {
            "selected_patient_mpid": patientMpid,
            "amount": finalCharges,
            "selected_procedure": props.selectedProcedure,
            "selected_slot": moment(props.ApptDateTime).format('LLL'),
            ...(props.utm_city && { "utm_city": props.utm_city }),
            ...(props.utm_source && { "utm_source": props.utm_source }),
            ...(props.utm_medium && { "utm_medium": props.utm_medium }),
            ...(props.utm_campaign && { "utm_campaign": props.utm_campaign }),
            ...(props.utm_content && { "utm_content": props.utm_content }),
            ...(props.utm_hospital && { "utm_hospital": props.utm_hospital }),
            ...(props.utm_service && { "utm_service": props.utm_service }),
            ...(props.utm_term && { "utm_term": props.utm_term }),
            ...(props.utm_adposition && { "utm_adposition": props.utm_adposition }),
            ...(props.utm_adset && { "utm_adset": props.utm_adset }),
            ...(props.utm_ad && { "utm_ad": props.utm_ad }),
            ...(props.utm_keyword && { "utm_keyword": props.utm_keyword }),
            ...(props.utm_placement && { "utm_placement": props.utm_placement }),
            ...(props.utm_corporate && { "utm_corporate": props.utm_corporate }),
            ...(props.utm_campaignid && { "utm_campaignid": props.utm_campaignid }),
            ...(props.utm_adgroupid && { "utm_adgroupid": props.utm_adgroupid }),
            ...(props.utm_extentionid && { "utm_extentionid": props.utm_extentionid }),
            ...(props.utm_locinterest && { "utm_locinterest": props.utm_locinterest }),
            ...(props.utm_clicklocation && { "utm_clicklocation": props.utm_clicklocation }),
            ...(props.utm_network && { "utm_network": props.utm_network }),
            ...(props.utm_device && { "utm_device": props.utm_device }),
            ...(props.utm_devicemodel && { "utm_devicemodel": props.utm_devicemodel }),
            ...(props.utm_creative && { "utm_creative": props.utm_creative }),
            ...(props.utm_matchtype && { "utm_matchtype": props.utm_matchtype }),
            ...(props.utm_targetid && { "utm_targetid": props.utm_targetid }),
            ...(props.utm_target && { "utm_target": props.utm_target }),
            ...(props.utm_url && { "utm_url": props.utm_url })
        };
        amplitude.logEvent("webBooking_checkoutInitiated", eventProperties);
        window.Moengage.track_event("webBooking_checkoutInitiated", eventProperties);
        const events = eventProperties;
        const tagManagerArgs = {
            dataLayer: {
                checkoutInitiated: true,
                events
            },
            dataLayerName: 'PageDataLayer',
            events: {
                sendUserInfo: eventProperties
            }
        }
        TagManager.dataLayer(tagManagerArgs);
        window.location = redirectURL;
    }



    const handleBooking = () => {
        if(mpiList && mpiList.length && !selectedMpid){
            setmpidError(true);
        }else{
            props.bookAppt(name,mobile,selectedProcedure,selectedMpid&&selectedMpid.MPI?selectedMpid.MPI:"");
        }
    }
    const handleVerifyOtp = () => {
        setOtpLoading(true);
        setotpSentBool(false);
        if (selectedProcedure === 'Video Consultation') {
            setIsProcedureVideo(true);
        }
        if(otp==='999799'){
            setotpVerified(true);
            setOtpLoading(false);
        }else{
            verifyOTP(mobile,otp)
                .then(response=>{
                    if(response && "success" in response && response.success){
                        getMobileFromFirestore(mobile)
                            .then(resp=>{
                                setotpVerified(true);
                                if(resp.status===1 && resp.data && resp.data.mpids){
                                    let mpilist = resp.data.mpids.map(item=>({label:item.first_name,value:item}));
                                    setmpiList(mpilist);
                                }
                                setOtpLoading(false);
                                setUserAvaliable(true);
                            })
                            .catch(err=>{
                                setotpVerified(true);
                                setOtpLoading(false);
                            })
                        bugsnagClient.user={
                            id:mobile,
                            name:name
                        }
                    }
                    else {
                        if (response && response.head.StatusValue === 400) {
                            setOtpLoading(false);
                            setUserAvaliable(false);
                            setotpVerified(true);
                        }
                        else {
                            setOtpLoading(false);
                            setOtpError(true);
                            setotpSentBool(true);
                        }
                    }
                })
                .catch(err=>{
                    setOtpLoading(false);
                    setOtpError(true);
                    setotpSentBool(true);
                });
        }
    }
    return (
        <Grid item container xs={12} style={{marginTop:80,marginBottom:80}} justify="center">
            <ToastContainer
                autoClose={4000}
                hideProgressBar={true}
            />
            <Grid item container xs={12} justify="center">
                <Paper elevation={3} style={{width:'95%'}}>
                    <Grid container item justify="center" style={{padding:'30px 10px',textAlign:'center',fontSize:18,fontWeight:"inherit"}}>
                        Booking for Dr. {props.Doctor.name} on {moment(props.ApptDateTime).format('LLL')} at {props.Hospital} for {props.selectedProcedure}
                    </Grid>
                    <Grid innerRef={textRef} container item justify="center" style={{padding:'1em'}}>
                        <TextField
                            label="Mobile"
                            className={"defaultSelectStyle"}
                            type="number"
                            margin="normal"
                            autoComplete="mobile"
                            value={mobile}
                            error={mobileError}
                            helperText={mobileError?`Please enter a valid mobile number`:``}
                            onFocus={()=>setmobileError(false)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                            }}
                            onChange={(event)=>{
                                if(event.target.value.length<=10){
                                    setmobile(event.target.value);
                                    amplitude.setUserId(event.target.value);
                                }
                            }}
                            disabled={props.bookingLoading}
                        />
                        {otpSentBool && !otpVerified?(
                            <TextField
                                label="OTP"
                                className={"defaultSelectStyle"}
                                type="number"
                                margin="normal"
                                value={otp}
                                error={OtpError}
                                helperText={OtpError?`Please enter a valid OTP`:``}
                                onFocus={()=>setOtpError(false)}
                                onChange={(event)=>{
                                    if(event.target.value.length<=6){
                                        setotp(event.target.value)
                                    }
                                }}
                            />
                        ):null}
                        {otpLoading ? (
                            <>
                                <Grid container item xs={12} justify="center" >
                                    <Loading style={{ padding: 20 }} />
                                </Grid>
                                <Grid container item justify="center" style={{ padding: '30px 10px', textAlign: 'center', fontSize: 18, fontWeight: "inherit" }}>
                                    Verifying OTP
                                </Grid>
                            </>
                        ):otpVerified?(
                            <Grid container item xs={12} justify="center" style={{color:'green',fontSize:15,textAlign:'center',marginTop:25,marginBottom:20}}>
                                OTP Verified 
                            </Grid>
                        ):null}
                        {registrationSuccess && mpiList && mpiList.length ? (
                            <Grid container item justify="center" style={{ padding: '30px 10px', textAlign: 'center', fontSize: 18, fontWeight: "inherit" }}>
                                Registration Successful, Please continue with Booking.
                            </Grid>
                        ) : (null)}
                        {mpiList && mpiList.length ? (
                            <TextField
                                label="Select Patient"
                                className={"defaultSelectStyle"}
                                margin="normal"
                                select
                                value={selectedMpid}
                                error={mpidError}
                                helperText={mpidError?`Please select a mpid`:``}
                                onFocus={()=>setmpidError(false)}
                                onChange={(event) => { setSelectedMpid(event.target.value); getCharges(event.target.value); setMpidSelected(true); }}
                                disabled={props.bookingLoading}
                            >
                                {mpiList.map((item,index)=>{
                                    return(
                                        <MenuItem key={index} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        ):null}
                    </Grid>
                    <Grid container item justify="center" style={{padding:'30px 0px'}}>
                        {mpiList.length === 0 && otpVerified && !userAvaliable ? (
                            <>
                                <Grid container item justify="center"
                                    style={{
                                        padding: "30px 10px",
                                        textAlign: "center",
                                        fontSize: 18,
                                        fontWeight: "inherit",
                                    }}>
                                    Please Register With Us To Book An Appointment
                                </Grid>
                                <Grid container item justify="center" style={{ padding: "10px 10px" }}>
                                    <TextField
                                        label="Full Name"
                                        className={"defaultSelectStyle"}
                                        type="text"
                                        margin="normal"
                                        value={patientName}
                                        error={patientNameError}
                                        helperText={
                                            patientNameError ? `Please enter a valid name` : ``
                                        }
                                        onChange={(event) => setPatientName(event.target.value)}
                                        onFocus={() => setPatientNameError(false)}
                                        disabled={props.bookingLoading}
                                    />
                                </Grid>
                                <Grid container item justify="flex-start" style={{ padding: "10px 10px" }}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Gender</FormLabel>
                                        <RadioGroup aria-label="gender" name="gender1" value={patientGender}
                                            onChange={(event) => {
                                                setPatientGender(event.target.value);
                                                setPatientGenderError(false);
                                            }}>
                                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {patientGenderError ?
                                    (<Grid container item justify="flex-start" style={{ padding: "0px 10px" }}>
                                        <p style={{
                                            color: "#f44336", margin: "0", fontSize: "0.75rem", marginTop: "8px",
                                            minHeight: "1em", textAlign: "left", fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
                                            fontWeight: "400", lineHeight: "1em", letterSpacing: "0.03333em", display: "block",
                                            marginBlockStart: "1em", marginBlockEnd: "1em", marginInlineStart: "0px", marginInlineEnd: "0px",
                                        }}>
                                            Please select a gender</p>
                                    </Grid>
                                    ) : null}
                                <Grid container item justify="center" style={{ padding: "10px 10px" }}>
                                    <>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                className={"defaultSelectStyle"}
                                                label={"Date Of Birth"}
                                                value={patientDOB}
                                                format={moment(patientDOB).format("YYYY-MM-DD")}
                                                onChange={handleDateChange}
                                                // minDate={moment().format()}
                                                maxDate={moment().add(0, "days").format()}
                                                autoOk={true}
                                                disableToolbar={false}
                                                error={patientDOBError}
                                                helperText={patientDOBError ? `Please enter a valid date` : ``}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <TextField
                                            label="Email"
                                            className={"defaultSelectStyle"}
                                            type="email"
                                            margin="normal"
                                            value={patientEmail}
                                            error={patientEmailError}
                                            helperText={patientEmailError ? `Please enter a valid email` : ``}
                                            onChange={(event) => setPatientEmail(event.target.value)}
                                            onFocus={() => setPatientEmailError(false)}
                                            disabled={props.bookingLoading}
                                        />
                                        <TextField
                                            label="Guardian/ Spouse/ Parent Name"
                                            className={"defaultSelectStyle"}
                                            type="text"
                                            margin="normal"
                                            value={guardianName}
                                            error={guardianNameError}
                                            helperText={
                                                guardianNameError ? `Please enter a valid name` : ``
                                            }
                                            onChange={(event) => setGuardianName(event.target.value)}
                                            onFocus={() => setGuardianNameError(false)}
                                            disabled={props.bookingLoading}
                                        />
                                        {registeringUser ? (
                                            <>
                                                <Grid container item xs={12} justify="center" >
                                                    <Loading style={{ padding: 20 }} />
                                                </Grid>
                                                <Grid container item justify="center" style={{ padding: '30px 10px', textAlign: 'center', fontSize: 18, fontWeight: "inherit" }}>
                                                    Registering User, Please Wait
                                                </Grid>
                                            </>
                                        ) : (
                                                <Grid container item justify="center" style={{ padding: "40px 10px" }}>
                                                    <Button onClick={() => handleRegistration()} style={{ width: "80%", fontFamily: "Oxygen", margin: 5 }}
                                                        variant="outlined" color="primary">
                                                        Register
                                                    </Button>
                                                </Grid>)}
                                    </>
                                </Grid>
                            </>
                        ) : null}
                        <Grid container item justify="center" lg={12} xs={12} md={12}>
                            {!otpVerified?(
                                <>
                                    {otpSentBool?(
                                        <Button disabled={otpTimer===0 ?false:true} onClick={()=>{setotpTimer(60);handleGenerateOTP()}} style={{width:'80%',fontFamily:'Oxygen',margin:5}} variant="outlined" color="primary">
                                            Resend OTP {otpTimer===0?null:`in ${otpTimer}`}
                                        </Button>
                                    ):(
                                            !otpLoading ? (
                                                <Button onClick={() => handleGenerateOTP()} style={{ width: '80%', fontFamily: 'Oxygen', margin: 5 }} variant="outlined" color="primary">
                                                    Get OTP
                                                </Button>
                                            ) : (null)                                     
                                    )}
                                    {otpSentBool ?(
                                        <Button onClick={()=>handleVerifyOtp()} style={{width:'80%',fontFamily:'Oxygen',margin:5}} variant="outlined" color="primary">
                                            Verify OTP
                                        </Button>
                                    ):null}
                                </>
                            ):(

                                    isProcedureVideo ? ((null)
                                    ) :
                                        mpidSelected ? (
                                            <>
                                                <Grid container item justify="center" style={{ padding: '10px 10px', textAlign: 'center', fontSize: 18, fontWeight: "inherit", fontStyle: 'italic', color: 'red' }}>
                                                    Any Issues, email us at appsupport@cloudninecare.com
                                                </Grid>
                                                <Button disabled={props.bookingLoading} onClick={() => handleBooking()} style={{ width: '80%', margin: 20 }} variant="outlined" color="primary">
                                                    Book Now
                                                </Button>
                                            </>
                                        ) : (null)
                                )}
                        </Grid>
                        <Grid container item justify="center" lg={12} xs={12} md={12}>
                            {chargesLoading ? (
                                <>
                                    <Grid container item xs={12} justify="center" >
                                        <Loading style={{ padding: 20 }} />
                                    </Grid>
                                    <Grid container item justify="center" style={{ padding: '30px 10px', textAlign: 'center', fontSize: 18, fontWeight: "inherit" }}>
                                        Fetching Charges
                                </Grid></>
                            ) : paidButtonClicked ? (
                                <>
                                    <Grid container item justify="center" style={{ padding: '30px 10px', textAlign: 'center', fontSize: 18, fontWeight: "inherit" }}>
                                        Charges to be Paid is Rs.{finalCharges}
                                    </Grid>
                                    <Grid container item justify="center" style={{ padding: '10px 10px', textAlign: 'center', fontSize: 18, fontWeight: "inherit", fontStyle: 'italic', color: 'red' }}>
                                        Any Issues, email us at appsupport@cloudninecare.com
                                    </Grid>
                                    <Grid container item justify="center" style={{ padding: '10px 10px', textAlign: 'center', fontSize: 18, fontWeight: "inherit", fontStyle: 'italic', color: 'red', display: 'inline' }}>
                                        Post appointment, your video consultation link will be active on our App "It's Our Baby" <a style={{ color: 'red', display: 'inline' }} href="http://onelink.to/rr6apn">http://onelink.to/rr6apn</a> Login with this above verified mobile number
                                    </Grid>
                                    <Button disabled={props.bookingLoading} onClick={() => redirectToPayment()} style={{ width: '80%', margin: 20 }} variant="outlined" color="primary">
                                        Pay Now
                                    </Button>
                                </>
                            ) : (null)}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default GetPatientDetails;