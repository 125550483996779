import React,{useState,useEffect} from 'react';
import {Grid,NoSsr} from '@material-ui/core';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import { bookAppointment, fetchProcedures } from "./../Api";
import Select from 'react-select';
import components from './../Utility/textFieldComps'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import GetSlots from "./getSlotsPage";
import GetPatientDetails from "./getPatientDetails";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import amplitude from './../Utility/Amplitude';
import TagManager from 'react-gtm-module';

function SingleDoctorPage(props) {
    const [ShrinkHospital,setShrinkHospital]=useState(false);
    const [Hospital,setHospital]=useState("");
    const [HospitalName,setHospitalName]=useState("");
    const [selectedDate, setselectedDate] = useState(null);
    const [SlotLoading,setSlotLoading]=useState(false);
    const [SlotId,setSlotId]=useState(null);
    const [SiteCode,setSiteCode]=useState(null);
    const [Procedures,setProcedures]=useState([]);
    let [ProcedureArr, setProcedureArr] = useState([]);
    const [Procedure, setProcedure] = useState("");
    const [procedureSelected, setProcedureSelected] = useState("");
    const [ShrinkProcedure, setShrinkProcedure] = useState(false);
    const [ApptDateTime,setApptDateTime]=useState(null);
    const [bookingLoading,setbookingLoading]=useState(false);
    const [hospitalData, setHospitalData] = useState("");

    useEffect(() => {
      if (props.hospitalAvaliable) {
        ProcedureArr = props.ProcedureArr;
        setSiteCode(props.siteCode);
      }
    });

    const handleHospital=(item)=>{
        const eventProperties = {
          "selected_hospital": item.label,
          ...(props.utm_city && { "utm_city": props.utm_city }),
          ...(props.utm_source && { "utm_source": props.utm_source }),
          ...(props.utm_medium && { "utm_medium": props.utm_medium }),
          ...(props.utm_campaign && { "utm_campaign": props.utm_campaign }),
          ...(props.utm_content && { "utm_content": props.utm_content }),
          ...(props.utm_hospital && { "utm_hospital": props.utm_hospital }),
          ...(props.utm_service && { "utm_service": props.utm_service }),
          ...(props.utm_term && { "utm_term": props.utm_term }),
          ...(props.utm_adposition && { "utm_adposition": props.utm_adposition }),
          ...(props.utm_adset && { "utm_adset": props.utm_adset }),
          ...(props.utm_ad && { "utm_ad": props.utm_ad }),
          ...(props.utm_keyword && { "utm_keyword": props.utm_keyword }),
          ...(props.utm_placement && { "utm_placement": props.utm_placement }),
          ...(props.utm_corporate && { "utm_corporate": props.utm_corporate }),
          ...(props.utm_campaignid && { "utm_campaignid": props.utm_campaignid }),
          ...(props.utm_adgroupid && { "utm_adgroupid": props.utm_adgroupid }),
          ...(props.utm_extentionid && { "utm_extentionid": props.utm_extentionid }),
          ...(props.utm_locinterest && { "utm_locinterest": props.utm_locinterest }),
          ...(props.utm_clicklocation && { "utm_clicklocation": props.utm_clicklocation }),
          ...(props.utm_network && { "utm_network": props.utm_network }),
          ...(props.utm_device && { "utm_device": props.utm_device }),
          ...(props.utm_devicemodel && { "utm_devicemodel": props.utm_devicemodel }),
          ...(props.utm_creative && { "utm_creative": props.utm_creative }),
          ...(props.utm_matchtype && { "utm_matchtype": props.utm_matchtype }),
          ...(props.utm_targetid && { "utm_targetid": props.utm_targetid }),
          ...(props.utm_target && { "utm_target": props.utm_target }),
          ...(props.utm_url && { "utm_url": props.utm_url })
        };
        amplitude.logEvent("webBooking_hospitalSelected", eventProperties);
        window.Moengage.track_event("webBooking_hospitalSelected", eventProperties);
        const events = eventProperties;
        const tagManagerArgs = {
            dataLayer: {
                events
            },
            dataLayerName: 'PageDataLayer',
            events: {
                sendUserInfo: eventProperties
            }
        }
        TagManager.dataLayer(tagManagerArgs);
        setHospitalName(item.label);
        setHospital(item);
        setSiteCode(item.value.siteCode);
        setselectedDate(null);
        setProcedure("");
        setProcedureSelected("");
        setProcedureArr([]);
      if (props.procedureAvaliable) {
        const procedure = [props.procedureSelected];
        let ProcedureData = procedure.map(item => ({ label: item, value: item }));
        setProcedureArr(ProcedureData);
      }
      else {
        fetchProcedures(props.DoctorData.entityId, item.value.siteCode).then(
          (response) => {
            if (response && response.data) {
              let ProcedureData = response.data.map((item) => ({
                label: item,
                value: item,
              }));
              setProcedureArr(ProcedureData);
            }
          }
        );
      }
    }

    const fetchProcedures = (entityId, siteCode) => {
        const url = 'https://cx-server.cloudninecare.com/qkams/get/procedures';
        const req_body = {
            "entityId": entityId,
            "siteCode": siteCode
        }     
        const fetchOpts = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(req_body)
        };

        return fetch(url, fetchOpts)
            .then(response =>
                response.json()).then(response => {
                    if (response && response.data) {
                        let ProcedureData = response.data.map(item => ({ label: item, value: item }));
                        setProcedureArr(ProcedureData);
                    }
                })
            .catch(error => {
                setProcedureArr([]);
            });
    }

    const handleProcedure = (item) => {
        setProcedure(item);
        setProcedureSelected(item.label);
        const eventProperties = {
          "selected_procedure": item.value,
          ...(props.utm_city && { "utm_city": props.utm_city }),
          ...(props.utm_source && { "utm_source": props.utm_source }),
          ...(props.utm_medium && { "utm_medium": props.utm_medium }),
          ...(props.utm_campaign && { "utm_campaign": props.utm_campaign }),
          ...(props.utm_content && { "utm_content": props.utm_content }),
          ...(props.utm_hospital && { "utm_hospital": props.utm_hospital }),
          ...(props.utm_service && { "utm_service": props.utm_service }),
          ...(props.utm_term && { "utm_term": props.utm_term }),
          ...(props.utm_adposition && { "utm_adposition": props.utm_adposition }),
          ...(props.utm_adset && { "utm_adset": props.utm_adset }),
          ...(props.utm_ad && { "utm_ad": props.utm_ad }),
          ...(props.utm_keyword && { "utm_keyword": props.utm_keyword }),
          ...(props.utm_placement && { "utm_placement": props.utm_placement }),
          ...(props.utm_corporate && { "utm_corporate": props.utm_corporate }),
          ...(props.utm_campaignid && { "utm_campaignid": props.utm_campaignid }),
          ...(props.utm_adgroupid && { "utm_adgroupid": props.utm_adgroupid }),
          ...(props.utm_extentionid && { "utm_extentionid": props.utm_extentionid }),
          ...(props.utm_locinterest && { "utm_locinterest": props.utm_locinterest }),
          ...(props.utm_clicklocation && { "utm_clicklocation": props.utm_clicklocation }),
          ...(props.utm_network && { "utm_network": props.utm_network }),
          ...(props.utm_device && { "utm_device": props.utm_device }),
          ...(props.utm_devicemodel && { "utm_devicemodel": props.utm_devicemodel }),
          ...(props.utm_creative && { "utm_creative": props.utm_creative }),
          ...(props.utm_matchtype && { "utm_matchtype": props.utm_matchtype }),
          ...(props.utm_targetid && { "utm_targetid": props.utm_targetid }),
          ...(props.utm_target && { "utm_target": props.utm_target }),
          ...(props.utm_url && { "utm_url": props.utm_url })
        };
        amplitude.logEvent("webBooking_procedureSelected", eventProperties);
        window.Moengage.track_event("webBooking_procedureSelected", eventProperties);
        const events = eventProperties;
        const tagManagerArgs = {
            dataLayer: {
                events
            },
            dataLayerName: 'PageDataLayer',
            events: {
                sendUserInfo: eventProperties
            }
        }
        TagManager.dataLayer(tagManagerArgs);
        setselectedDate(null);
    }
    const handleDateChange=(item)=>{
        setselectedDate(item);
    }
    const handleSlotSelection=(item)=>{
        setSlotId(item.id);
        setApptDateTime(item.apptDateTime);
        setProcedures(item.procedures);
    }
    const theme = useTheme();
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            height: 250,
            minWidth: 290,
        },
        input: {
            display: 'flex',
            padding: 0,
            height: 'auto',
        },
        valueContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
            fontFamily:"Oxygen"
        },
        chip: {
            margin: theme.spacing(0.5, 0.25),
        },
        chipFocused: {
            backgroundColor: emphasize(
                theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
                0.08,
            ),
            fontFamily:"Oxygen"
        },
        noOptionsMessage: {
            padding: theme.spacing(1, 2),
        },
        singleValue: {
            fontFamily:"Oxygen"
        },
        placeholder: {
            position: 'absolute',
            left: 2,
            bottom: 6,
            fontFamily:"Oxygen"
        },
        paper: {
            position: 'absolute',
            zIndex: 1,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
        divider: {
            height: theme.spacing(2),
        },
    }));
    const classes = useStyles();
    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    }

    const resetSelections=()=>{
        setSiteCode(null);
        setSlotId(null);
        setApptDateTime(null);
        setbookingLoading(false);
        setselectedDate(null);
        setHospital("");
        setProcedure("");
        setProcedureSelected("");
        setProcedureArr([]);
      }
    const bookAppt = (name, mobile, procedure, mpid) => {
        setbookingLoading(true);
        bookAppointment(SlotId, procedure, mobile, name, SiteCode, props.DoctorData.name, ApptDateTime, mpid)
            .then(resp=>{
                if(resp && resp.status===1){
                  toast.success("Booked Successfully!", {
                    position: toast.POSITION.TOP_CENTER
                  });
                  setbookingLoading(false);
                  resetSelections();        
                }else{
                  toast.error("Booking Failed!", {
                    position: toast.POSITION.TOP_CENTER
                  });
                }
              })
            .catch(err=>{
                resetSelections();
                toast.error("Booking Failed!", {
                    position: toast.POSITION.TOP_CENTER
                });
            })
    };
    return (
      <Grid item xs={12}>
        <ToastContainer autoClose={4000} hideProgressBar={true} />
        <NoSsr>
          <Grid container item justify="center">
          {!props.hospitalAvaliable ? (
            <Grid container item justify="center" style={{ padding: 10 }}>
              <Select
                classes={classes}
                styles={selectStyles}
                inputId="react-select-multiple"
                components={components}
                className={"defaultSelectStyle"}
                placeholder=""
                TextFieldProps={{
                  label: "Select Hospital",
                  InputLabelProps: {
                    htmlFor: "react-select-multiple",
                    shrink: ShrinkHospital || Hospital ? true : false,
                  },
                }}
                onFocus={() => setShrinkHospital(true)}
                onBlur={() => setShrinkHospital(false)}
                value={props.hospitalAvaliable ? props.hospitalData : Hospital}
                options={props.HospitalArr}
                onChange={handleHospital}
                isSearchable={false}
                isLoading={props.HospitalArr.length === 0 ? true : false}
              />
            </Grid>
          ) : null}
            <Grid container item justify="center" style={{ padding: 10 }}>
              <Select
                classes={classes}
                styles={selectStyles}
                inputId="react-select-multiple"
                components={components}
                className={"defaultSelectStyle"}
                placeholder=""
                TextFieldProps={{
                  label: "Select Procedure",
                  InputLabelProps: {
                    htmlFor: "react-select-multiple",
                    shrink: ShrinkProcedure || Procedure ? true : false,
                  },
                }}
                onFocus={() => setShrinkProcedure(true)}
                onBlur={() => setShrinkProcedure(false)}
                value={{ label: procedureSelected }}
                options={props.hospitalAvaliable ? props.ProcedureArr : ProcedureArr}
                onChange={handleProcedure}
                isSearchable={false}
                isDisabled={Hospital && props.hospitalAvaliable ? true : false}
                isLoading={Hospital && ProcedureArr.length === 0 ? true : false}
              />
            </Grid>
            <Grid
              container
              item
              justify="center"
              style={{ padding: "10px 10px" }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  className={"defaultSelectStyle"}
                  label={"Select Date"}
                  value={selectedDate}
                  format={moment(selectedDate).format("DD-MM-YYYY")}
                  onChange={handleDateChange}
                  minDate={moment().format()}
                  maxDate={moment().add(59, "days").format()}
                  autoOk={true}
                  disabled={Hospital || !props.hospitalAvailable? false : true}
                  disableToolbar={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </NoSsr>
        {SiteCode && selectedDate ? (
          <>
            <GetSlots
              goNext={(item) => handleSlotSelection(item)}
              selectedDoc={props.DoctorData}
              selectedSiteCode={SiteCode}
              setSlotLoading={setSlotLoading}
              SelectedDate={selectedDate}
              selectedProcedure={Procedure.value}
              entityId={props.DoctorData.entityId}
              utm_source={props.utm_source}
              utm_medium={props.utm_medium}
              utm_campaign={props.utm_campaign}
              utm_content={props.utm_content}
              utm_city={props.utm_city}
              utm_hospital={props.utm_hospital}
              utm_service={props.utm_service}
              utm_term={props.utmTerm}
              utm_adposition={props.utmAdposition}
              utm_adset={props.utmAdset}
              utm_ad={props.utmAd}
              utm_keyword={props.utmKeyword}
              utm_placement={props.utmPlacement}
              utm_corporate={props.utmCorporate}
              utm_campaignid={props.utmCampaignId}
              utm_adgroupid={props.utmAdgroupId}
              utm_extentionid={props.utmExtentionId}
              utm_locinterest={props.utmLocInterest}
              utm_clicklocation={props.utmClickLocation}
              utm_network={props.utmNetwork}
              utm_device={props.utmDevice}
              utm_devicemodel={props.utmDeviceModel}
              utm_creative={props.utmCreative}
              utm_matchtype={props.utmMatchType}
              utm_targetid={props.utmTargetId}
              utm_target={props.utmTarget}
              utm_url={props.utmUrl}
            />
            {SlotId && !SlotLoading ? (
              <GetPatientDetails
                bookAppt={(name, mobile, procedures, mpid) =>
                  bookAppt(name, mobile, procedures, mpid)
                }
                ApptDateTime={ApptDateTime}
                Doctor={props.DoctorData}
                Hospital={HospitalName}
                bookingLoading={bookingLoading}
                Procedures={Procedures}
                selectedProcedure={Procedure.value}
                SlotId={SlotId}
                SiteCode={SiteCode}
                utm_source={props.utm_source}
                utm_medium={props.utm_medium}
                utm_campaign={props.utm_campaign}
                utm_content={props.utm_content}
                utm_city={props.utm_city}
                utm_hospital={props.utm_hospital}
                utm_service={props.utm_service}
                utm_term={props.utmTerm}
                utm_adposition={props.utmAdposition}
                utm_adset={props.utmAdset}
                utm_ad={props.utmAd}
                utm_keyword={props.utmKeyword}
                utm_placement={props.utmPlacement}
                utm_corporate={props.utmCorporate}
                utm_campaignid={props.utmCampaignId}
                utm_adgroupid={props.utmAdgroupId}
                utm_extentionid={props.utmExtentionId}
                utm_locinterest={props.utmLocInterest}
                utm_clicklocation={props.utmClickLocation}
                utm_network={props.utmNetwork}
                utm_device={props.utmDevice}
                utm_devicemodel={props.utmDeviceModel}
                utm_creative={props.utmCreative}
                utm_matchtype={props.utmMatchType}
                utm_targetid={props.utmTargetId}
                utm_target={props.utmTarget}
                utm_url={props.utmUrl}
              />
            ) : null}
          </>
        ) : null}
      </Grid>
    );
}

export default SingleDoctorPage;