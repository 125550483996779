import React, { useState, useEffect } from "react";
import { Grid, NoSsr } from "@material-ui/core";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import { fetchHospitals, fetchDoctors } from "./../Api";
import Select from "react-select";
import components from "./../Utility/textFieldComps";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import amplitude from "./../Utility/Amplitude";
import TagManager from "react-gtm-module";

const cities = [
  "Bengaluru",
  "Noida",
  "Chandigarh",
  "Chennai",
  "Mumbai",
  "Pune",
  "Gurugram",
  "New Delhi",
  "Faridabad",
  "Hyderabad"
];

function GetDetailsPage(props) {
  const [City, setCity] = useState("");
  const [ShrinkCity, setShrinkCity] = useState(false);
  const [HospitalArr, setHospitalArr] = useState([]);
  const [Hospital, setHospital] = useState("");
  const [hospitalSelected, setHospitalSelected] = useState("");
  const [selectedDocEntityId, setSelectedDocEntityId] = useState("");
  const [ShrinkHospital, setShrinkHospital] = useState(false);
  const [DepartmentArr, setDepartmentArr] = useState([]);
  const [Department, setDepartment] = useState("");
  const [ShrinkDepartment, setShrinkDepartment] = useState(false);
  const [DoctorArr, setDoctorArr] = useState([]);
  const [ProcedureArr, setProcedureArr] = useState([]);
  const [Doctor, setDoctor] = useState("");
  const [Procedure, setProcedure] = useState("");
  const [procedureSelected, setProcedureSelected] = useState("");
  const [ShrinkDoc, setShrinkDoc] = useState(false);
  const [ShrinkProcedure, setShrinkProcedure] = useState(false);
  const [selectedDate, setselectedDate] = useState(null);

  useEffect(() => {
    let city = "";
    if (props.cityAvaliable) {
      setCity(props.citySelected);
      city =
        props.citySelected.slice(0, 1).toUpperCase() +
        props.citySelected.slice(1, props.citySelected.length);

      const eventProperties = {
        selected_city: city,
        ...(props.utm_city && { utm_city: props.utm_city }),
        ...(props.utm_source && { utm_source: props.utm_source }),
        ...(props.utm_medium && { utm_medium: props.utm_medium }),
        ...(props.utm_campaign && { utm_campaign: props.utm_campaign }),
        ...(props.utm_content && { utm_content: props.utm_content }),
        ...(props.utm_hospital && { utm_hospital: props.utm_hospital }),
        ...(props.utm_service && { utm_service: props.utm_service }),
        ...(props.utm_term && { utm_term: props.utm_term }),
        ...(props.utm_adposition && { utm_adposition: props.utm_adposition }),
        ...(props.utm_adset && { utm_adset: props.utm_adset }),
        ...(props.utm_ad && { utm_ad: props.utm_ad }),
        ...(props.utm_keyword && { utm_keyword: props.utm_keyword }),
        ...(props.utm_placement && { utm_placement: props.utm_placement }),
        ...(props.utm_corporate && { utm_corporate: props.utm_corporate }),
        ...(props.utm_campaignid && { utm_campaignid: props.utm_campaignid }),
        ...(props.utm_adgroupid && { utm_adgroupid: props.utm_adgroupid }),
        ...(props.utm_extentionid && {
          utm_extentionid: props.utm_extentionid,
        }),
        ...(props.utm_locinterest && {
          utm_locinterest: props.utm_locinterest,
        }),
        ...(props.utm_clicklocation && {
          utm_clicklocation: props.utm_clicklocation,
        }),
        ...(props.utm_network && { utm_network: props.utm_network }),
        ...(props.utm_device && { utm_device: props.utm_device }),
        ...(props.utm_devicemodel && {
          utm_devicemodel: props.utm_devicemodel,
        }),
        ...(props.utm_creative && { utm_creative: props.utm_creative }),
        ...(props.utm_matchtype && { utm_matchtype: props.utm_matchtype }),
        ...(props.utm_targetid && { utm_targetid: props.utm_targetid }),
        ...(props.utm_target && { utm_target: props.utm_target }),
        ...(props.utm_url && { utm_url: props.utm_url }),
      };
      amplitude.logEvent("webBooking_citySelected", eventProperties);
      window.Moengage.track_event("webBooking_citySelected", eventProperties);
      const events = eventProperties;
      const tagManagerArgs = {
        dataLayer: {
          events,
        },
        dataLayerName: "PageDataLayer",
        events: {
          sendUserInfo: eventProperties,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
      fetchHospitals().then((resp) => {
        if (
          resp &&
          resp.status &&
          resp.status === 1 &&
          resp.response &&
          resp.response.data &&
          resp.response.data.getHospitals
        ) {
          if (props.hospitalTypeAvaliable) {
            if (props.hospitalTypeSelected == "all") {
              let data = resp.response.data.getHospitals.filter(
                (el) => el.cityName == city
              );
              let hospitalData = [];
              data.forEach((element) => {
                hospitalData.push({
                  label: element.name,
                  value: {
                    [element.siteCode]: element.name,
                  },
                });
              });
              setHospitalArr(hospitalData);
            } else {
              if (props.hospitalTypeSelected == "clinics") {
                let data = resp.response.data.getHospitals.filter(
                  (el) => el.cityName == city && !el.isCloudnineHosp
                );
                let hospitalData = [];
                data.forEach((element) => {
                  hospitalData.push({
                    label: element.name,
                    value: {
                      [element.siteCode]: element.name,
                    },
                  });
                });
                setHospitalArr(hospitalData);
              } else {
                let data = resp.response.data.getHospitals.filter(
                  (el) => el.cityName == city && el.isCloudnineHosp
                );
                let hospitalData = [];
                data.forEach((element) => {
                  hospitalData.push({
                    label: element.name,
                    value: {
                      [element.siteCode]: element.name,
                    },
                  });
                });
                setHospitalArr(hospitalData);
              }
            }
          } else {
            let data = resp.response.data.getHospitals.filter(
              (el) => el.cityName == city && el.isCloudnineHosp
            );
            let hospitalData = [];
            data.forEach((element) => {
              hospitalData.push({
                label: element.name,
                value: {
                  [element.siteCode]: element.name,
                },
              });
            });
            setHospitalArr(hospitalData);
          }
        }
      });
    }
  }, [
    props.cityAvaliable,
    props.citySelected,
    props.utm_source,
    props.utm_medium,
    props.utm_campaign,
    props.utm_content,
    props.utm_city,
    props.utm_hospital,
    props.utm_service,
    props.utm_term,
    props.utm_adposition,
    props.utm_adset,
    props.utm_ad,
    props.utm_keyword,
    props.utm_placement,
    props.utm_corporate,
    props.utm_campaignid,
    props.utm_adgroupid,
    props.utm_extentionid,
    props.utm_locinterest,
    props.utm_clicklocation,
    props.utm_network,
    props.utm_device,
    props.utm_devicemodel,
    props.utm_creative,
    props.utm_matchtype,
    props.utm_targetid,
    props.utm_target,
    props.utm_url,
  ]);

  useEffect(() => {
    let department = "";
    if (props.departmentAvaliable) {
      setDepartment(props.departmentSelected);
      department = props.departmentSelected;
      const eventProperties = {
        selected_department: department,
        ...(props.utm_city && { utm_city: props.utm_city }),
        ...(props.utm_source && { utm_source: props.utm_source }),
        ...(props.utm_medium && { utm_medium: props.utm_medium }),
        ...(props.utm_campaign && { utm_campaign: props.utm_campaign }),
        ...(props.utm_content && { utm_content: props.utm_content }),
        ...(props.utm_hospital && { utm_hospital: props.utm_hospital }),
        ...(props.utm_service && { utm_service: props.utm_service }),
        ...(props.utm_term && { utm_term: props.utm_term }),
        ...(props.utm_adposition && { utm_adposition: props.utm_adposition }),
        ...(props.utm_adset && { utm_adset: props.utm_adset }),
        ...(props.utm_ad && { utm_ad: props.utm_ad }),
        ...(props.utm_keyword && { utm_keyword: props.utm_keyword }),
        ...(props.utm_placement && { utm_placement: props.utm_placement }),
        ...(props.utm_corporate && { utm_corporate: props.utm_corporate }),
        ...(props.utm_campaignid && { utm_campaignid: props.utm_campaignid }),
        ...(props.utm_adgroupid && { utm_adgroupid: props.utm_adgroupid }),
        ...(props.utm_extentionid && {
          utm_extentionid: props.utm_extentionid,
        }),
        ...(props.utm_locinterest && {
          utm_locinterest: props.utm_locinterest,
        }),
        ...(props.utm_clicklocation && {
          utm_clicklocation: props.utm_clicklocation,
        }),
        ...(props.utm_network && { utm_network: props.utm_network }),
        ...(props.utm_device && { utm_device: props.utm_device }),
        ...(props.utm_devicemodel && {
          utm_devicemodel: props.utm_devicemodel,
        }),
        ...(props.utm_creative && { utm_creative: props.utm_creative }),
        ...(props.utm_matchtype && { utm_matchtype: props.utm_matchtype }),
        ...(props.utm_targetid && { utm_targetid: props.utm_targetid }),
        ...(props.utm_target && { utm_target: props.utm_target }),
        ...(props.utm_url && { utm_url: props.utm_url }),
      };
      amplitude.logEvent("webBooking_departmentSelected", eventProperties);
      window.Moengage.track_event(
        "webBooking_departmentSelected",
        eventProperties
      );
      const events = eventProperties;
      const tagManagerArgs = {
        dataLayer: {
          events,
        },
        dataLayerName: "PageDataLayer",
        events: {
          sendUserInfo: eventProperties,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [
    props.departmentAvaliable,
    props.departmentSelected,
    props.utm_source,
    props.utm_medium,
    props.utm_campaign,
    props.utm_content,
    props.utm_city,
    props.utm_hospital,
    props.utm_service,
    props.utm_term,
    props.utm_adposition,
    props.utm_adset,
    props.utm_ad,
    props.utm_keyword,
    props.utm_placement,
    props.utm_corporate,
    props.utm_campaignid,
    props.utm_adgroupid,
    props.utm_extentionid,
    props.utm_locinterest,
    props.utm_clicklocation,
    props.utm_network,
    props.utm_device,
    props.utm_devicemodel,
    props.utm_creative,
    props.utm_matchtype,
    props.utm_targetid,
    props.utm_target,
    props.utm_url,
  ]);

  const handleSelectCity = (item) => {
    props.resetData();
    props.resetSlotId();
    props.resetSelectedDate();
    setDoctor("");
    setHospital("");
    setHospitalSelected("");
    setCity(item.value);
    const eventProperties = {
      selected_city: item.value,
      ...(props.utm_city && { utm_city: props.utm_city }),
      ...(props.utm_source && { utm_source: props.utm_source }),
      ...(props.utm_medium && { utm_medium: props.utm_medium }),
      ...(props.utm_campaign && { utm_campaign: props.utm_campaign }),
      ...(props.utm_content && { utm_content: props.utm_content }),
      ...(props.utm_hospital && { utm_hospital: props.utm_hospital }),
      ...(props.utm_service && { utm_service: props.utm_service }),
      ...(props.utm_term && { utm_term: props.utm_term }),
      ...(props.utm_adposition && { utm_adposition: props.utm_adposition }),
      ...(props.utm_adset && { utm_adset: props.utm_adset }),
      ...(props.utm_ad && { utm_ad: props.utm_ad }),
      ...(props.utm_keyword && { utm_keyword: props.utm_keyword }),
      ...(props.utm_placement && { utm_placement: props.utm_placement }),
      ...(props.utm_corporate && { utm_corporate: props.utm_corporate }),
      ...(props.utm_campaignid && { utm_campaignid: props.utm_campaignid }),
      ...(props.utm_adgroupid && { utm_adgroupid: props.utm_adgroupid }),
      ...(props.utm_extentionid && { utm_extentionid: props.utm_extentionid }),
      ...(props.utm_locinterest && { utm_locinterest: props.utm_locinterest }),
      ...(props.utm_clicklocation && {
        utm_clicklocation: props.utm_clicklocation,
      }),
      ...(props.utm_network && { utm_network: props.utm_network }),
      ...(props.utm_device && { utm_device: props.utm_device }),
      ...(props.utm_devicemodel && { utm_devicemodel: props.utm_devicemodel }),
      ...(props.utm_creative && { utm_creative: props.utm_creative }),
      ...(props.utm_matchtype && { utm_matchtype: props.utm_matchtype }),
      ...(props.utm_targetid && { utm_targetid: props.utm_targetid }),
      ...(props.utm_target && { utm_target: props.utm_target }),
      ...(props.utm_url && { utm_url: props.utm_url }),
    };
    amplitude.logEvent("webBooking_citySelected", eventProperties);
    window.Moengage.track_event("webBooking_citySelected", eventProperties);
    const events = eventProperties;
    const tagManagerArgs = {
      dataLayer: {
        events,
      },
      dataLayerName: "PageDataLayer",
      events: {
        sendUserInfo: eventProperties,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    setDepartment("");
    setProcedure("");
    setProcedureSelected("");
    fetchHospitals().then((resp) => {
      if (
        resp &&
        resp.status &&
        resp.status === 1 &&
        resp.response &&
        resp.response.data &&
        resp.response.data.getHospitals
      ) {
        if (props.hospitalTypeAvaliable) {
          if (props.hospitalTypeSelected === "all") {
            let data = resp.response.data.getHospitals.filter(
              (el) => el.cityName == item.value
            );
            let hospitalData = [];
            data.forEach((element) => {
              hospitalData.push({
                label: element.name,
                value: {
                  [element.siteCode]: element.name,
                },
              });
            });
            setHospitalArr(hospitalData);
          } else {
            if (props.hospitalTypeSelected == "clinics") {
              let data = resp.response.data.getHospitals.filter(
                (el) => el.cityName == item.value && !el.isCloudnineHosp
              );
              let hospitalData = [];
              data.forEach((element) => {
                hospitalData.push({
                  label: element.name,
                  value: {
                    [element.siteCode]: element.name,
                  },
                });
              });
              setHospitalArr(hospitalData);
            } else {
              let data = resp.response.data.getHospitals.filter(
                (el) => el.cityName == item.value && el.isCloudnineHosp
              );
              let hospitalData = [];
              data.forEach((element) => {
                hospitalData.push({
                  label: element.name,
                  value: {
                    [element.siteCode]: element.name,
                  },
                });
              });
              setHospitalArr(hospitalData);
            }
          }
        } else {
          let data = resp.response.data.getHospitals.filter(
            (el) => el.cityName == item.value && el.isCloudnineHosp
          );
          let hospitalData = [];
          data.forEach((element) => {
            hospitalData.push({
              label: element.name,
              value: {
                [element.siteCode]: element.name,
              },
            });
          });
          setHospitalArr(hospitalData);
        }
      }
    });
  };
  const handleHospital = (item) => {
    let selectedSiteCode = "";
    setHospitalSelected(item.label);
    props.resetSlotId();
    props.resetData();
    props.resetSelectedDate();
    setDoctor("");
    setDoctorArr([]);
    setProcedure("");
    setProcedureSelected("");
    setProcedureArr([]);
    setDepartmentArr([]);
    setselectedDate(null);
    setDepartment("");
    selectedSiteCode = Object.keys(item.value)[0];
    if (selectedSiteCode !== "") {
      fetchDoctors(selectedSiteCode)
        .then((resp) => {
          let deptDataArr = [];
          let docData = resp.data.map((item, index) => {
            if (!deptDataArr.includes(item.appDepartment)) {
              deptDataArr.push(item.appDepartment);
            }
            if (resp.data.length === index + 1) {
              setDepartmentArr(
                deptDataArr.map((item) => ({ label: item, value: item }))
              );
            }
            return {
              label: item.name,
              value: item,
            };
          });
          setDoctorArr(docData);
          if (props.departmentAvaliable) {
            let dept =
              props.departmentSelected.slice(0, 1).toUpperCase() +
              props.departmentSelected.slice(
                1,
                props.departmentSelected.length
              );
            setDepartment(dept);
          }
        })
        .catch((err) => {
          setDoctorArr([]);
        });
    }
    setHospital(item.value);
    const eventProperties = {
      selected_hospital: item.label,
      ...(props.utm_city && { utm_city: props.utm_city }),
      ...(props.utm_source && { utm_source: props.utm_source }),
      ...(props.utm_medium && { utm_medium: props.utm_medium }),
      ...(props.utm_campaign && { utm_campaign: props.utm_campaign }),
      ...(props.utm_content && { utm_content: props.utm_content }),
      ...(props.utm_hospital && { utm_hospital: props.utm_hospital }),
      ...(props.utm_service && { utm_service: props.utm_service }),
      ...(props.utm_term && { utm_term: props.utm_term }),
      ...(props.utm_adposition && { utm_adposition: props.utm_adposition }),
      ...(props.utm_adset && { utm_adset: props.utm_adset }),
      ...(props.utm_ad && { utm_ad: props.utm_ad }),
      ...(props.utm_keyword && { utm_keyword: props.utm_keyword }),
      ...(props.utm_placement && { utm_placement: props.utm_placement }),
      ...(props.utm_corporate && { utm_corporate: props.utm_corporate }),
      ...(props.utm_campaignid && { utm_campaignid: props.utm_campaignid }),
      ...(props.utm_adgroupid && { utm_adgroupid: props.utm_adgroupid }),
      ...(props.utm_extentionid && { utm_extentionid: props.utm_extentionid }),
      ...(props.utm_locinterest && { utm_locinterest: props.utm_locinterest }),
      ...(props.utm_clicklocation && {
        utm_clicklocation: props.utm_clicklocation,
      }),
      ...(props.utm_network && { utm_network: props.utm_network }),
      ...(props.utm_device && { utm_device: props.utm_device }),
      ...(props.utm_devicemodel && { utm_devicemodel: props.utm_devicemodel }),
      ...(props.utm_creative && { utm_creative: props.utm_creative }),
      ...(props.utm_matchtype && { utm_matchtype: props.utm_matchtype }),
      ...(props.utm_targetid && { utm_targetid: props.utm_targetid }),
      ...(props.utm_target && { utm_target: props.utm_target }),
      ...(props.utm_url && { utm_url: props.utm_url }),
    };
    amplitude.logEvent("webBooking_hospitalSelected", eventProperties);
    window.Moengage.track_event("webBooking_hospitalSelected", eventProperties);
    const events = eventProperties;
    const tagManagerArgs = {
      dataLayer: {
        events,
      },
      dataLayerName: "PageDataLayer",
      events: {
        sendUserInfo: eventProperties,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };
  const handleDepartment = (item) => {
    props.resetSlotId();
    props.resetData();
    props.resetSelectedDate();
    setDoctor("");
    setProcedure("");
    setProcedureSelected("");
    setDepartment(item.value);
    const eventProperties = {
      selected_department: item.value,
      ...(props.utm_city && { utm_city: props.utm_city }),
      ...(props.utm_source && { utm_source: props.utm_source }),
      ...(props.utm_medium && { utm_medium: props.utm_medium }),
      ...(props.utm_campaign && { utm_campaign: props.utm_campaign }),
      ...(props.utm_content && { utm_content: props.utm_content }),
      ...(props.utm_hospital && { utm_hospital: props.utm_hospital }),
      ...(props.utm_service && { utm_service: props.utm_service }),
      ...(props.utm_term && { utm_term: props.utm_term }),
      ...(props.utm_adposition && { utm_adposition: props.utm_adposition }),
      ...(props.utm_adset && { utm_adset: props.utm_adset }),
      ...(props.utm_ad && { utm_ad: props.utm_ad }),
      ...(props.utm_keyword && { utm_keyword: props.utm_keyword }),
      ...(props.utm_placement && { utm_placement: props.utm_placement }),
      ...(props.utm_corporate && { utm_corporate: props.utm_corporate }),
      ...(props.utm_campaignid && { utm_campaignid: props.utm_campaignid }),
      ...(props.utm_adgroupid && { utm_adgroupid: props.utm_adgroupid }),
      ...(props.utm_extentionid && { utm_extentionid: props.utm_extentionid }),
      ...(props.utm_locinterest && { utm_locinterest: props.utm_locinterest }),
      ...(props.utm_clicklocation && {
        utm_clicklocation: props.utm_clicklocation,
      }),
      ...(props.utm_network && { utm_network: props.utm_network }),
      ...(props.utm_device && { utm_device: props.utm_device }),
      ...(props.utm_devicemodel && { utm_devicemodel: props.utm_devicemodel }),
      ...(props.utm_creative && { utm_creative: props.utm_creative }),
      ...(props.utm_matchtype && { utm_matchtype: props.utm_matchtype }),
      ...(props.utm_targetid && { utm_targetid: props.utm_targetid }),
      ...(props.utm_target && { utm_target: props.utm_target }),
      ...(props.utm_url && { utm_url: props.utm_url }),
    };
    amplitude.logEvent("webBooking_departmentSelected", eventProperties);
    window.Moengage.track_event(
      "webBooking_departmentSelected",
      eventProperties
    );
    const events = eventProperties;
    const tagManagerArgs = {
      dataLayer: {
        events,
      },
      dataLayerName: "PageDataLayer",
      events: {
        sendUserInfo: eventProperties,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    setselectedDate(null);
  };
  const handleDoctor = (item) => {
    setProcedure("");
    setProcedureSelected("");
    setProcedureArr([]);
    setDoctor(item);
    const eventProperties = {
      selected_doctor: item.value.name,
      ...(props.utm_city && { utm_city: props.utm_city }),
      ...(props.utm_source && { utm_source: props.utm_source }),
      ...(props.utm_medium && { utm_medium: props.utm_medium }),
      ...(props.utm_campaign && { utm_campaign: props.utm_campaign }),
      ...(props.utm_content && { utm_content: props.utm_content }),
      ...(props.utm_hospital && { utm_hospital: props.utm_hospital }),
      ...(props.utm_service && { utm_service: props.utm_service }),
      ...(props.utm_term && { utm_term: props.utm_term }),
      ...(props.utm_adposition && { utm_adposition: props.utm_adposition }),
      ...(props.utm_adset && { utm_adset: props.utm_adset }),
      ...(props.utm_ad && { utm_ad: props.utm_ad }),
      ...(props.utm_keyword && { utm_keyword: props.utm_keyword }),
      ...(props.utm_placement && { utm_placement: props.utm_placement }),
      ...(props.utm_corporate && { utm_corporate: props.utm_corporate }),
      ...(props.utm_campaignid && { utm_campaignid: props.utm_campaignid }),
      ...(props.utm_adgroupid && { utm_adgroupid: props.utm_adgroupid }),
      ...(props.utm_extentionid && { utm_extentionid: props.utm_extentionid }),
      ...(props.utm_locinterest && { utm_locinterest: props.utm_locinterest }),
      ...(props.utm_clicklocation && {
        utm_clicklocation: props.utm_clicklocation,
      }),
      ...(props.utm_network && { utm_network: props.utm_network }),
      ...(props.utm_device && { utm_device: props.utm_device }),
      ...(props.utm_devicemodel && { utm_devicemodel: props.utm_devicemodel }),
      ...(props.utm_creative && { utm_creative: props.utm_creative }),
      ...(props.utm_matchtype && { utm_matchtype: props.utm_matchtype }),
      ...(props.utm_targetid && { utm_targetid: props.utm_targetid }),
      ...(props.utm_target && { utm_target: props.utm_target }),
      ...(props.utm_url && { utm_url: props.utm_url }),
    };
    amplitude.logEvent("webBooking_doctorSelected", eventProperties);
    window.Moengage.track_event("webBooking_doctorSelected", eventProperties);
    const events = eventProperties;
    const tagManagerArgs = {
      dataLayer: {
        events,
      },
      dataLayerName: "PageDataLayer",
      events: {
        sendUserInfo: eventProperties,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    setSelectedDocEntityId(item.value.entityId);
    if (props.procedureAvaliable) {
      const procedure = [props.procedureSelected];
      let ProcedureData = procedure.map((item) => ({
        label: item,
        value: item,
      }));
      setProcedureArr(ProcedureData);
    } else {
      fetchProcedures(item.value.entityId, Object.keys(Hospital)[0]);
    }
    setselectedDate(null);
    props.resetSelectedDate();
    props.resetSlotId();
  };

  const fetchProcedures = (entityId, siteCode) => {
    const url = "https://cx-server.cloudninecare.com/qkams/get/procedures";
    const req_body = {
      entityId: entityId,
      siteCode: siteCode,
    };

    const fetchOpts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req_body),
    };

    return fetch(url, fetchOpts)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data) {
          let ProcedureData = response.data.map((item) => ({
            label: item,
            value: item,
          }));
          setProcedureArr(ProcedureData);
        }
      })
      .catch((error) => {
        setProcedureArr([]);
      });
  };

  const handleProcedure = (item) => {
    setProcedure(item);
    setProcedureSelected(item.label);
    const eventProperties = {
      selected_procedure: item.value,
      ...(props.utm_city && { utm_city: props.utm_city }),
      ...(props.utm_source && { utm_source: props.utm_source }),
      ...(props.utm_medium && { utm_medium: props.utm_medium }),
      ...(props.utm_campaign && { utm_campaign: props.utm_campaign }),
      ...(props.utm_content && { utm_content: props.utm_content }),
      ...(props.utm_hospital && { utm_hospital: props.utm_hospital }),
      ...(props.utm_service && { utm_service: props.utm_service }),
      ...(props.utm_term && { utm_term: props.utm_term }),
      ...(props.utm_adposition && { utm_adposition: props.utm_adposition }),
      ...(props.utm_adset && { utm_adset: props.utm_adset }),
      ...(props.utm_ad && { utm_ad: props.utm_ad }),
      ...(props.utm_keyword && { utm_keyword: props.utm_keyword }),
      ...(props.utm_placement && { utm_placement: props.utm_placement }),
      ...(props.utm_corporate && { utm_corporate: props.utm_corporate }),
      ...(props.utm_campaignid && { utm_campaignid: props.utm_campaignid }),
      ...(props.utm_adgroupid && { utm_adgroupid: props.utm_adgroupid }),
      ...(props.utm_extentionid && { utm_extentionid: props.utm_extentionid }),
      ...(props.utm_locinterest && { utm_locinterest: props.utm_locinterest }),
      ...(props.utm_clicklocation && {
        utm_clicklocation: props.utm_clicklocation,
      }),
      ...(props.utm_network && { utm_network: props.utm_network }),
      ...(props.utm_device && { utm_device: props.utm_device }),
      ...(props.utm_devicemodel && { utm_devicemodel: props.utm_devicemodel }),
      ...(props.utm_creative && { utm_creative: props.utm_creative }),
      ...(props.utm_matchtype && { utm_matchtype: props.utm_matchtype }),
      ...(props.utm_targetid && { utm_targetid: props.utm_targetid }),
      ...(props.utm_target && { utm_target: props.utm_target }),
      ...(props.utm_url && { utm_url: props.utm_url }),
    };
    amplitude.logEvent("webBooking_procedureSelected", eventProperties);
    window.Moengage.track_event(
      "webBooking_procedureSelected",
      eventProperties
    );
    const events = eventProperties;
    const tagManagerArgs = {
      dataLayer: {
        events,
      },
      dataLayerName: "PageDataLayer",
      events: {
        sendUserInfo: eventProperties,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    setselectedDate(null);
    props.resetSelectedDate();
    props.resetSlotId();
  };

  const handleDateChange = (item) => {
    setselectedDate(item);
    props.goNext({
      selectedDoc: Doctor.value,
      selectedSiteCode: Hospital,
      selectedDate: item,
      selectedProcedure: Procedure.value,
      entityId: selectedDocEntityId,
    });
    props.resetSlotId();
  };
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      height: 250,
      minWidth: 290,
    },
    input: {
      display: "flex",
      padding: 0,
      height: "auto",
    },
    valueContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      alignItems: "center",
      overflow: "hidden",
      fontFamily: "Oxygen",
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      ),
      fontFamily: "Oxygen",
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {
      fontFamily: "Oxygen",
    },
    placeholder: {
      position: "absolute",
      left: 2,
      bottom: 6,
      fontFamily: "Oxygen",
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
  }));
  useEffect(() => {
    if (props.SiteCode === null) {
      setCity("");
      setHospital("");
      setHospitalSelected("");
      setDepartment("");
      setDoctor("");
      setProcedure("");
      setProcedureSelected("");
      setselectedDate(null);
    }
  }, [props.SiteCode, props.Doctor]);
  const classes = useStyles();
  // const disableDoctor=City&&Hospital?false:true;
  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
      },
    }),
  };

  return (
    <Grid item xs={12}>
      <NoSsr>
        <Grid container item justify="center">
          {!props.cityAvaliable ? (
            <Grid container item justify="center" style={{ padding: 10 }}>
              <Select
                classes={classes}
                styles={selectStyles}
                inputId="react-select-multiple"
                components={components}
                className={"defaultSelectStyle"}
                placeholder=""
                TextFieldProps={{
                  label: "Select City",
                  InputLabelProps: {
                    htmlFor: "react-select-multiple",
                    shrink: ShrinkCity || City ? true : false,
                  },
                }}
                onFocus={() => setShrinkCity(true)}
                onBlur={() => setShrinkCity(false)}
                value={{ label: City }}
                options={cities.map((item) => ({ label: item, value: item }))}
                onChange={handleSelectCity}
                isSearchable={false}
              />
            </Grid>
          ) : null}
          <Grid container item justify="center" style={{ padding: 10 }}>
            <Select
              classes={classes}
              styles={selectStyles}
              inputId="react-select-multiple"
              components={components}
              className={"defaultSelectStyle"}
              placeholder=""
              TextFieldProps={{
                label: "Select Hospital",
                InputLabelProps: {
                  htmlFor: "react-select-multiple",
                  shrink: ShrinkHospital || Hospital ? true : false,
                },
              }}
              onFocus={() => setShrinkHospital(true)}
              onBlur={() => setShrinkHospital(false)}
              value={{ label: hospitalSelected }}
              options={HospitalArr}
              onChange={handleHospital}
              isSearchable={false}
              isDisabled={City ? false : true}
              isLoading={City && HospitalArr.length === 0 ? true : false}
            />
          </Grid>
          {!props.departmentAvaliable ? (
            <Grid container item justify="center" style={{ padding: 10 }}>
              <Select
                classes={classes}
                styles={selectStyles}
                inputId="react-select-multiple"
                components={components}
                className={"defaultSelectStyle"}
                placeholder=""
                TextFieldProps={{
                  label: "Select Department",
                  InputLabelProps: {
                    htmlFor: "react-select-multiple",
                    shrink: ShrinkDepartment || Department ? true : false,
                  },
                }}
                onFocus={() => setShrinkDepartment(true)}
                onBlur={() => setShrinkDepartment(false)}
                value={{ label: Department }}
                options={DepartmentArr}
                onChange={handleDepartment}
                isSearchable={false}
                isDisabled={City && Hospital ? false : true}
                isLoading={
                  Hospital && DepartmentArr.length === 0 ? true : false
                }
              />
            </Grid>
          ) : null}
          <Grid container item justify="center" style={{ padding: 10 }}>
            <Select
              classes={classes}
              styles={selectStyles}
              inputId="react-select-multiple"
              components={components}
              className={"defaultSelectStyle"}
              placeholder=""
              TextFieldProps={{
                label: "Select Doctor",
                InputLabelProps: {
                  htmlFor: "react-select-multiple",
                  shrink: ShrinkDoc || Doctor ? true : false,
                },
              }}
              onFocus={() => setShrinkDoc(true)}
              onBlur={() => setShrinkDoc(false)}
              value={Doctor}
              options={DoctorArr.filter((item) =>
                item.value.appDepartment.includes(Department) ? 1 : 0
              )}
              onChange={handleDoctor}
              isSearchable={true}
              isDisabled={Hospital && City && Department ? false : true}
              isLoading={
                Hospital && Department && DoctorArr.length === 0 ? true : false
              }
            />
          </Grid>
          <Grid container item justify="center" style={{ padding: 10 }}>
            <Select
              classes={classes}
              styles={selectStyles}
              inputId="react-select-multiple"
              components={components}
              className={"defaultSelectStyle"}
              placeholder=""
              TextFieldProps={{
                label: "Select Procedure",
                InputLabelProps: {
                  htmlFor: "react-select-multiple",
                  shrink: ShrinkProcedure || Procedure ? true : false,
                },
              }}
              onFocus={() => setShrinkProcedure(true)}
              onBlur={() => setShrinkProcedure(false)}
              value={{ label: procedureSelected }}
              options={ProcedureArr}
              onChange={handleProcedure}
              isSearchable={false}
              isDisabled={
                Hospital && City && Department && Doctor ? false : true
              }
              isLoading={
                Hospital && Doctor && Department && ProcedureArr.length === 0
                  ? true
                  : false
              }
            />
          </Grid>
          <Grid
            container
            item
            justify="center"
            style={{ padding: "10px 10px" }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className={"defaultSelectStyle"}
                label={"Select Date"}
                value={selectedDate}
                format={moment(selectedDate).format("DD-MM-YYYY")}
                onChange={handleDateChange}
                minDate={moment().format()}
                maxDate={moment().add(59, "days").format()}
                autoOk={true}
                disabled={Doctor ? false : true}
                disableToolbar={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </NoSsr>
    </Grid>
  );
}

export default GetDetailsPage;
