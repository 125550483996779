import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import GetDetails from "./Pages/getDetailsPage";
import GetSlots from "./Pages/getSlotsPage";
import SingleDoctor from "./Pages/singleDoctorPage";
import GetPatientDetails from "./Pages/getPatientDetails";
import { bookAppointment, fetchDoctorFromId, fetchProcedures, fetchLeadsqaureId } from './Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  // const [Step,setStep]=useState(0);
  const [DoctorId, setDoctorId] = useState(null);
  const [Doctor, setDoctor] = useState(null);
  const [SiteCode, setSiteCode] = useState(null);
  const [SlotId, setSlotId] = useState(null);
  const [Procedures, setProcedures] = useState([]);
  const [HospitalArr, setHospitalArr] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState("");
  const [entityId, setEntityId] = useState("");
  const [ApptDateTime, setApptDateTime] = useState(null);
  const [bookingLoading, setbookingLoading] = useState(false);
  const [SlotLoading, setSlotLoading] = useState(false);
  const [SelectedDate, setSelectedDate] = useState(null);
  const [Doctor_2, setDoctor_2] = useState(null);
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmContent, setUtmContent] = useState("");
  const [utmCity, setUtmCity] = useState("");
  const [utmHospital, setUtmHospital] = useState("");
  const [utmTerm, setUtmTerm] = useState("");
  const [utmAdposition, setUtmAdposition] = useState("");
  const [utmAdset, setUtmAdset] = useState("");
  const [utmAd, setUtmAd] = useState("");
  const [utmKeyword, setUtmKeyword] = useState("");
  const [utmPlacement, setUtmPlacement] = useState("");
  const [utmCorporate, setUtmCorporate] = useState("");
  const [utmCampaignId, setUtmCampaignId] = useState("");
  const [utmAdgroupId, setUtmAdgroupId] = useState("");
  const [utmExtentionId, setUtmExtentionId] = useState("");
  const [utmLocInterest, setUtmLocInterest] = useState("");
  const [utmClickLocation, setUtmClickLocation] = useState("");
  const [utmNetwork, setUtmNetwork] = useState("");
  const [utmDevice, setUtmDevice] = useState("");
  const [utmDeviceModel, setUtmDeviceModel] = useState("");
  const [utmCreative, setUtmCreative] = useState("");
  const [utmMatchType, setUtmMatchType] = useState("");
  const [utmTargetId, setUtmTargetId] = useState("");
  const [utmTarget, setUtmTarget] = useState("");
  const [utmUrl, setUtmUrl] = useState("");
  const [utmService, setUtmService] = useState("");
  const [citySelected, setCitySelected] = useState("");
  const [cityAvaliable, setCityAvaliable] = useState(false);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [departmentAvaliable, setDepartmentAvaliable] = useState(false);
  const [procedureSelected, setProcedureSelected] = useState("");
  const [procedureAvaliable, setProcedureAvaliable] = useState(false);

  const [hospitalAvaliable, setHospitalAvaliable] = useState(false);
  const [hospitalId, setHospitalId] = useState("");
  const [hospitalData, setHospitalData] = useState("");
  const [hosSiteCode, setHosSiteCode] = useState("");
  const [ProcedureArr, setProcedureArr] = useState([]);
  const [hospitalTypeSelected, setHospitalTypeSelected] = useState("");
  const [hospitalTypeAvaliable, setHospitalTypeAvaliable] = useState(false);

  useEffect(() => {

    let url = new URL(window.location.href);
    let docId = "";
    let city = "";
    let procedure = "";
    let department = "";
    let hosId = "";
    let show = "";
    if (url && url.searchParams && url.searchParams.get("docId")) {
      docId = url.searchParams.get("docId");
    }
    if (url && url.searchParams && url.searchParams.get("city")) {
      if (url.searchParams.get("city") == "delhi" || url.searchParams.get("city") == "new delhi") {
        city = "New Delhi";
        setCitySelected("New Delhi");
      } else {
        city = url.searchParams.get("city");
        setCitySelected(url.searchParams.get("city"));
      }
    }
    if (url && url.searchParams && url.searchParams.get("procedure")) {
      procedure = url.searchParams.get("procedure");
    }
    if (url && url.searchParams && url.searchParams.get("department")) {
      department = url.searchParams.get("department");
    }
    if (url && url.searchParams && url.searchParams.get("hosId")) {
      hosId = url.searchParams.get("hosId");
    }
    if (url && url.searchParams && url.searchParams.get("show")) {
      show = url.searchParams.get("show");
    }
    if (url && url.searchParams) {
      setUtmSource(url.searchParams.get("utm_source"));
      setUtmMedium(url.searchParams.get("utm_medium"));
      setUtmCampaign(url.searchParams.get("utm_campaign"));
      setUtmContent(url.searchParams.get("utm_content"));
      setUtmCity(url.searchParams.get("utm_city"));
      setUtmHospital(url.searchParams.get("utm_hospital"));
      setUtmService(url.searchParams.get("utm_service"));
      setUtmTerm(url.searchParams.get("utm_term"));
      setUtmAdposition(url.searchParams.get("utm_adposition"));
      setUtmAdset(url.searchParams.get("utm_adset"));
      setUtmAd(url.searchParams.get("utm_ad"));
      setUtmKeyword(url.searchParams.get("utm_keyword"));
      setUtmPlacement(url.searchParams.get("utm_placement"));
      setUtmCorporate(url.searchParams.get("utm_corporate"));
      setUtmCampaignId(url.searchParams.get("utm_campaignid"));
      setUtmAdgroupId(url.searchParams.get("utm_adgroupid"));
      setUtmExtentionId(url.searchParams.get("utm_extentionid"));
      setUtmLocInterest(url.searchParams.get("utm_locinterest"));
      setUtmClickLocation(url.searchParams.get("utm_clicklocation"));
      setUtmNetwork(url.searchParams.get("utm_network"));
      setUtmDevice(url.searchParams.get("utm_device"));
      setUtmDeviceModel(url.searchParams.get("utm_devicemodel"));
      setUtmCreative(url.searchParams.get("utm_creative"));
      setUtmMatchType(url.searchParams.get("utm_matchtype"));
      setUtmTargetId(url.searchParams.get("utm_targetid"));
      setUtmTarget(url.searchParams.get("utm_target"));
      setUtmUrl(url.searchParams.get("utm_url"));
      setProcedureSelected(url.searchParams.get("procedure"));
      setDepartmentSelected(url.searchParams.get("department"));
      setHospitalTypeSelected(url.searchParams.get("show"));
    }

    if (city) {
      setCityAvaliable(true);
    }
    if (procedure) {
      setProcedureAvaliable(true);
    }
    if (department) {
      setDepartmentAvaliable(true);
    }
    if (hosId !== "") {
      setHospitalAvaliable(true);
      setHospitalId(hosId);
    }
    if (show) {
      setHospitalTypeAvaliable(true);
    }
    if (docId !== "" || hosId !== "") {
      let z = [];
      setDoctorId(docId);
      fetchDoctorFromId(docId)
        .then(resp => {
          if (resp && resp.status && resp.data && resp.data.length) {
            setDoctor_2(resp.data[0]);
            setHosSiteCode(resp.data[0].entity.id);
            if (show != "") {
              if (show == "all") {
                z = resp.data[0].getHospitals;
              } else {
                if (show == "clinics") {
                  z = resp.data[0].getHospitals.filter(el => !el.isCloudnineHosp);
                } else {
                  z = resp.data[0].getHospitals.filter(el => el.isCloudnineHosp);
                }
              }
            } else {
              z = resp.data[0].getHospitals.filter(el => el.isCloudnineHosp);
            }
          }

          if (z && z.length) {
            let hospitalArrValue = z.map((item) => ({
              label: item.name,
              value: item,
            }))
            setHospitalArr(hospitalArrValue);
            z.map((item, index) => {
              if (item.id == url.searchParams.get("hosId")) {
                setHospitalData({
                  label: item.name,
                  value: {
                    id: item.id,
                    name: item.name,
                    siteCode: item.siteCode,
                  },
                });

                setHosSiteCode(item.siteCode);

                fetchProcedures(resp.data[0].entity.id, item.siteCode).then(
                  (response) => {
                    if (response && response.data) {
                      let ProcedureData = response.data.map((item) => ({
                        label: item,
                        value: item,
                      }));
                      setProcedureArr(ProcedureData);
                    }
                  }
                );
              }
            });
          }

        })
    }
  }, [])

  const handleStep1 = (item) => {
    setDoctor(item.selectedDoc);
    setSiteCode(item.selectedSiteCode);
    setSelectedDate(item.selectedDate);
    setSelectedProcedure(item.selectedProcedure);
    setEntityId(item.entityId);
    // setStep(Step+1);
  };
  const handleStep2 = (item) => {
    setSlotId(item.id);
    setApptDateTime(item.apptDateTime);
    setProcedures(item.procedures);
    // setStep(Step+1);
  };
  const resetSelections = () => {
    setDoctor(null);
    setSiteCode(null);
    setSlotId(null);
    setApptDateTime(null);
    setbookingLoading(false);
    setSelectedDate(null);
  }
  const bookAppt = (name, mobile, procedure, mpid) => {
    setbookingLoading(true);
    bookAppointment(SlotId, procedure, mobile, name, Object.keys(SiteCode)[0], Doctor.name, ApptDateTime, mpid)
      .then(resp => {
        if (resp && resp.status === 1) {
          toast.success("Booked Successfully!", {
            position: toast.POSITION.TOP_CENTER
          });
          setbookingLoading(false);
          resetSelections();
        } else {
          toast.error("Booking Failed!", {
            position: toast.POSITION.TOP_CENTER
          });
        }
      })
      .catch(err => {
        resetSelections();
        toast.error("Booking Failed!", {
          position: toast.POSITION.TOP_CENTER
        });
      })
  };
  const resetData = () => {
    setDoctor(null);
  };
  const resetSlotId = () => {
    setSlotId(null);
  };
  const resetSelectedDate = () => {
    setSelectedDate(null);
  }
  if (DoctorId && DoctorId.length) {
    return (
      <Grid item container xs={12} style={{ paddingTop: 40 }} justify="center">
        <Grid item container xs={12} lg={12}>
          <SingleDoctor
            HospitalArr={HospitalArr}
            DoctorId={DoctorId}
            DoctorData={Doctor_2}
            utm_source={utmSource}
            utm_medium={utmMedium}
            utm_campaign={utmCampaign}
            utm_content={utmContent}
            utm_city={utmCity}
            utm_hospital={utmHospital}
            utm_service={utmService}
            utm_term={utmTerm}
            utm_adposition={utmAdposition}
            utm_adset={utmAdset}
            utm_ad={utmAd}
            utm_keyword={utmKeyword}
            utm_placement={utmPlacement}
            utm_corporate={utmCorporate}
            utm_campaignid={utmCampaignId}
            utm_adgroupid={utmAdgroupId}
            utm_extentionid={utmExtentionId}
            utm_locinterest={utmLocInterest}
            utm_clicklocation={utmClickLocation}
            utm_network={utmNetwork}
            utm_device={utmDevice}
            utm_devicemodel={utmDeviceModel}
            utm_creative={utmCreative}
            utm_matchtype={utmMatchType}
            utm_targetid={utmTargetId}
            utm_target={utmTarget}
            utm_url={utmUrl}
            procedureSelected={procedureSelected}
            procedureAvaliable={procedureAvaliable}
            hospitalAvaliable={hospitalAvaliable}
            hospitalId={hospitalId}
            ProcedureArr={ProcedureArr}
            hospitalData={hospitalData}
            siteCode={hosSiteCode}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid item container xs={12} style={{ paddingTop: 40 }} justify="center">
        <ToastContainer autoClose={4000} hideProgressBar={true} />
        <Grid item container xs={12} lg={12}>
          <GetDetails
            goNext={(selectedDoc) => handleStep1(selectedDoc)}
            resetData={resetData}
            resetSlotId={resetSlotId}
            resetSelectedDate={resetSelectedDate}
            Doctor={Doctor}
            SiteCode={SiteCode}
            utm_source={utmSource}
            utm_medium={utmMedium}
            utm_campaign={utmCampaign}
            utm_content={utmContent}
            utm_city={utmCity}
            utm_hospital={utmHospital}
            utm_service={utmService}
            utm_term={utmTerm}
            utm_adposition={utmAdposition}
            utm_adset={utmAdset}
            utm_ad={utmAd}
            utm_keyword={utmKeyword}
            utm_placement={utmPlacement}
            utm_corporate={utmCorporate}
            utm_campaignid={utmCampaignId}
            utm_adgroupid={utmAdgroupId}
            utm_extentionid={utmExtentionId}
            utm_locinterest={utmLocInterest}
            utm_clicklocation={utmClickLocation}
            utm_network={utmNetwork}
            utm_device={utmDevice}
            utm_devicemodel={utmDeviceModel}
            utm_creative={utmCreative}
            utm_matchtype={utmMatchType}
            utm_targetid={utmTargetId}
            utm_target={utmTarget}
            utm_url={utmUrl}
            citySelected={citySelected}
            cityAvaliable={cityAvaliable}
            procedureSelected={procedureSelected}
            procedureAvaliable={procedureAvaliable}
            departmentSelected={departmentSelected}
            departmentAvaliable={departmentAvaliable}
            hospitalTypeSelected={hospitalTypeSelected}
            hospitalTypeAvaliable={hospitalTypeAvaliable}
          />
          {SiteCode && Doctor && SelectedDate ? (
            <>
              <GetSlots
                goNext={(item) => handleStep2(item)}
                selectedDoc={Doctor}
                selectedSiteCode={Object.keys(SiteCode)[0]}
                setSlotLoading={setSlotLoading}
                SelectedDate={SelectedDate}
                selectedProcedure={selectedProcedure}
                entityId={entityId}
                utm_source={utmSource}
                utm_medium={utmMedium}
                utm_campaign={utmCampaign}
                utm_content={utmContent}
                utm_city={utmCity}
                utm_hospital={utmHospital}
                utm_service={utmService}
                utm_term={utmTerm}
                utm_adposition={utmAdposition}
                utm_adset={utmAdset}
                utm_ad={utmAd}
                utm_keyword={utmKeyword}
                utm_placement={utmPlacement}
                utm_corporate={utmCorporate}
                utm_campaignid={utmCampaignId}
                utm_adgroupid={utmAdgroupId}
                utm_extentionid={utmExtentionId}
                utm_locinterest={utmLocInterest}
                utm_clicklocation={utmClickLocation}
                utm_network={utmNetwork}
                utm_device={utmDevice}
                utm_devicemodel={utmDeviceModel}
                utm_creative={utmCreative}
                utm_matchtype={utmMatchType}
                utm_targetid={utmTargetId}
                utm_target={utmTarget}
                utm_url={utmUrl}
              />
              {SlotId && !SlotLoading ? (
                <GetPatientDetails
                  bookAppt={(name, mobile, procedures, mpid) =>
                    bookAppt(name, mobile, procedures, mpid)
                  }
                  ApptDateTime={ApptDateTime}
                  Doctor={Doctor}
                  Hospital={Object.values(SiteCode)[0]}
                  bookingLoading={bookingLoading}
                  Procedures={Procedures}
                  SlotId={SlotId}
                  SiteCode={Object.keys(SiteCode)[0]}
                  selectedProcedure={selectedProcedure}
                  utm_source={utmSource}
                  utm_medium={utmMedium}
                  utm_campaign={utmCampaign}
                  utm_content={utmContent}
                  utm_city={utmCity}
                  utm_hospital={utmHospital}
                  utm_service={utmService}
                  utm_term={utmTerm}
                  utm_adposition={utmAdposition}
                  utm_adset={utmAdset}
                  utm_ad={utmAd}
                  utm_keyword={utmKeyword}
                  utm_placement={utmPlacement}
                  utm_corporate={utmCorporate}
                  utm_campaignid={utmCampaignId}
                  utm_adgroupid={utmAdgroupId}
                  utm_extentionid={utmExtentionId}
                  utm_locinterest={utmLocInterest}
                  utm_clicklocation={utmClickLocation}
                  utm_network={utmNetwork}
                  utm_device={utmDevice}
                  utm_devicemodel={utmDeviceModel}
                  utm_creative={utmCreative}
                  utm_matchtype={utmMatchType}
                  utm_targetid={utmTargetId}
                  utm_target={utmTarget}
                  utm_url={utmUrl}
                />
              ) : null}
            </>
          ) : null}
          {/* <GetPatientDetails goNext={()=>bookAppt()} ApptDateTime={"ApptDateTime"} Doctor={"Doctor"} Hospital={"Object.values(SiteCode)[0]"} Procedures={['Consultation','Follow Up']}/> */}
        </Grid>
      </Grid>
    );
  }
}

export default App;
