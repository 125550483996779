import React,{useState,useEffect} from 'react';
import {Grid,Paper,Button} from '@material-ui/core';
import {fetchSlots} from './../Api'
import Loading from '@material-ui/core/CircularProgress';
import moment from "moment";
import amplitude from './../Utility/Amplitude';
import TagManager from 'react-gtm-module';

function GetSlots(props) {
    const [Slots,setSlots]=useState([]);
    const [slotsLoading,setSlotsLoading]=useState(false);
    const [SelectedTime,setSelectedTime]=useState(null);
    const [SelectedDate,setSelectedDate]=useState(null);
    let slotBool=Slots && Slots.length === 60 ? false : true;
    // console.log(SelectedTime);
    // console.log(props);
    const handleButtonPress = (item) =>{
        setSelectedTime(item.startTime);
        const eventProperties = {
            "selected_slot": moment(item.startTime).format('LLL'),
            ...(props.utm_city && { "utm_city": props.utm_city }),
            ...(props.utm_source && { "utm_source": props.utm_source }),
            ...(props.utm_medium && { "utm_medium": props.utm_medium }),
            ...(props.utm_campaign && { "utm_campaign": props.utm_campaign }),
            ...(props.utm_content && { "utm_content": props.utm_content }),
            ...(props.utm_hospital && { "utm_hospital": props.utm_hospital }),
            ...(props.utm_service && { "utm_service": props.utm_service }),
            ...(props.utm_term && { "utm_term": props.utm_term }),
            ...(props.utm_adposition && { "utm_adposition": props.utm_adposition }),
            ...(props.utm_adset && { "utm_adset": props.utm_adset }),
            ...(props.utm_ad && { "utm_ad": props.utm_ad }),
            ...(props.utm_keyword && { "utm_keyword": props.utm_keyword }),
            ...(props.utm_placement && { "utm_placement": props.utm_placement }),
            ...(props.utm_corporate && { "utm_corporate": props.utm_corporate }),
            ...(props.utm_campaignid && { "utm_campaignid": props.utm_campaignid }),
            ...(props.utm_adgroupid && { "utm_adgroupid": props.utm_adgroupid }),
            ...(props.utm_extentionid && { "utm_extentionid": props.utm_extentionid }),
            ...(props.utm_locinterest && { "utm_locinterest": props.utm_locinterest }),
            ...(props.utm_clicklocation && { "utm_clicklocation": props.utm_clicklocation }),
            ...(props.utm_network && { "utm_network": props.utm_network }),
            ...(props.utm_device && { "utm_device": props.utm_device }),
            ...(props.utm_devicemodel && { "utm_devicemodel": props.utm_devicemodel }),
            ...(props.utm_creative && { "utm_creative": props.utm_creative }),
            ...(props.utm_matchtype && { "utm_matchtype": props.utm_matchtype }),
            ...(props.utm_targetid && { "utm_targetid": props.utm_targetid }),
            ...(props.utm_target && { "utm_target": props.utm_target }),
            ...(props.utm_url && { "utm_url": props.utm_url })
        };
        amplitude.logEvent("webBooking_slotSelected", eventProperties);
        window.Moengage.track_event("webBooking_slotSelected", eventProperties);
        const events = eventProperties;
        const tagManagerArgs = {
            dataLayer: {
                events
            },
            dataLayerName: 'PageDataLayer',
            events: {
                sendUserInfo: eventProperties
            }
        }
        TagManager.dataLayer(tagManagerArgs);
        props.goNext({id:item.id,apptDateTime:item.startTime,procedures:item.procedures});
    }
    const filterFor3DaysCondition=(item)=>moment(item.date,"YYYY-MM-DD").diff(moment(SelectedDate),'day')<2 && moment(item.date,"YYYY-MM-DD").diff(moment(SelectedDate),'day')>=0
    useEffect(()=>{
        if(slotBool){
            setSlotsLoading(true);
            fetchSlots(props.entityId, props.selectedSiteCode, props.selectedProcedure)
                .then(resp=>{
                    setSlotsLoading(false);
                    if(resp.status){
                        setSlots(resp.data);
                    }else{
                        setSlots([]);
                    }
                })
                .catch(err=>{
                    setSlotsLoading(false);
                    setSlots([]);
                })
        }
        setSelectedDate(props.SelectedDate);
        setSelectedTime(null);
    }, [props.selectedDoc, props.selectedSiteCode, props.SelectedDate, props.selectedProcedure, props.entityId, slotBool]);
    return (
        <Grid item xs={12} style={{paddingTop:50}}>
            <Grid container item justify="center">
                {slotsLoading ?(
                    <>
                        <Grid container item xs={12} justify="center" >
                            <Loading style={{ padding: 20 }} />
                        </Grid>
                        <Grid container item justify="center" style={{ padding: '30px 10px', textAlign: 'center', fontSize: 18, fontWeight: "inherit" }}>
                            Fetching Slots
                        </Grid>
                    </>
                ):Slots.length?(
                    <Grid container item justify="center" xs={12}>
                        {Slots.filter((item)=>filterFor3DaysCondition(item)?1:0).map((item,index)=>{
                            return(
                                <Paper key={index} elevation={3} style={{padding:'10px 0px',width:'95%',margin:'10px 0px'}}>
                                    <Grid container item justify="center" xs={12} style={{padding:'5px 0px'}}>
                                        {moment(item.date,'YYYY-MM-DD').format('DD MMM YYYY')}
                                    </Grid>
                                    {item.slots.length?(
                                        <Grid container item justify="center" xs={12} style={{padding:'8px 0px'}}>
                                            {item.slots.map((item, index) =>
                                                <Grid key={index} container item justify="center" xs={6} md={4} lg={2}>
                                                    <Button onClick={()=>handleButtonPress(item)} style={{width:'80%',margin:5,color:SelectedTime===item.startTime?'white':'unset',backgroundColor:SelectedTime===item.startTime?'green':'unset'}} variant={SelectedTime===item.startTime?null:"outlined"}>
                                                        {moment(item.startTime).format('h:mm a')}
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    ):(
                                        <Grid container item justify="center" xs={12} style={{padding:'15px 0px'}}>
                                            Not Slots
                                        </Grid>
                                    )}
                                </Paper>
                            )
                        })}
                    </Grid>
                ):null}
            </Grid>
        </Grid>
    );
}

export default GetSlots;